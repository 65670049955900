import React from 'react'
import { connect } from "react-redux"
import { Field, reduxForm, change, formValueSelector } from "redux-form"
import { PrimaryButton, Stack } from "@fluentui/react"
import _ from "lodash"
import * as FormHepler from "./formHelper"

class ItemGradeSearchForm extends React.Component {

    componentDidMount() {
        const { change } = this.props
        change("type", {
            key: "all",
            text: "All"
        })
        change("grade", {
            key: "all",
            text: "All"
        })
    }

    componentDidUpdate(prevProps) {
        const { type, change } = this.props;
        if (prevProps.type !== type) {
            change("grade", {
                key: "all",
                text: "All"
            })
        }
    }

    render() {
        const { handleSubmit, items = [], type, userAccess, onPrint, canDownload, onSearch } = this.props
        const groupedItems = _.groupBy(items, (item) => item.itemName.toLowerCase())
        const itemTypes = Object.keys(groupedItems).map(item => {
            return {
                key: item,
                text: item.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                    return letter.toUpperCase()
                })
            }
        })
        const itemsGradesByType = _.groupBy(items.filter(item => item.itemName.toLowerCase() === type?.key?.toLowerCase()), item => item.grade.toLowerCase())
        return (
            <form onSubmit={handleSubmit} noValidate>
                <Stack
                    horizontal={true}
                    tokens={{ childrenGap: 15 }}
                >
                    <Field
                        iconProps={{ iconName: 'Search' }}
                        name="type"
                        placeholder="Filter By Type"
                        options={[
                            {
                                key: "all",
                                text: "All"
                            },
                            ...itemTypes
                        ]}
                        component={FormHepler.RenderDropDown}
                    />
                    <Field
                        iconProps={{ iconName: 'Search' }}
                        name="grade"
                        placeholder="Filter By Grade"
                        options={[
                            {
                                key: "all",
                                text: "All"
                            },
                            ..._.sortBy(Object.keys(itemsGradesByType).map(item => {
                                return {
                                    key: item,
                                    text: item.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                                        return letter.toUpperCase()
                                    })
                                }
                            }), (item) => item.text)
                        ]}
                        component={FormHepler.RenderDropDown}
                    />
                    <Field
                        name="closingDate"
                        placeholder="Select Closing Date"
                        component={FormHepler.RenderDatePicker}
                        maxDate={new Date()}
                    />
                    {(userAccess === "SA" && onSearch && (<PrimaryButton iconProps={{ iconName: 'Search' }} type="button" onClick={onSearch}>Search</PrimaryButton>)) || null}
                    {(userAccess === "SA" && onPrint && (<PrimaryButton disabled={!canDownload} iconProps={{ iconName: 'Print' }} type="button" onClick={onPrint}>Print</PrimaryButton>)) || null}
                </Stack>
            </form>
        )
    }

}

const ItemGradeSearchFormMapped = reduxForm({
    form: "itemGradeSearchForm",
    enableReinitialize: true,
})(ItemGradeSearchForm)

const getInitialForm = () => {
    return {
        type: {
            key: "all",
            text: "All"
        },
        grade: {
            key: "all",
            text: "All"
        }
    }
}

const formSelector = formValueSelector("itemGradeSearchForm")

const mapStateToProps = ({ meta, ...state }) => ({
    items: meta.items,
    initialValues: getInitialForm(),
    type: formSelector(state, 'type')
})

const mapDispatchToProps = () => ({
    change
})

export default connect(mapStateToProps, mapDispatchToProps)(ItemGradeSearchFormMapped)