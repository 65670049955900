import * as actions from "../actions/actionTypes"
import { global } from "./initialState"

const globalReducer = (state = global, action) => {
  switch (action.type) {
    case actions.UPDATE_GLOBAL_ERROR:
      return {
        ...state,
        serviceErrorStatus: action.payload
      }
    default:
      return state
  }
}

export default globalReducer;