import React from "react"
import { connect } from "react-redux"
import { Field, reduxForm, change } from "redux-form"
import { Stack } from "@fluentui/react"
import _ from "lodash"
import * as FormHepler from "./formHelper"

class ItemSearchForm extends React.Component {

    componentDidMount() {
        const { change } = this.props
        change("query", "")
    }

    render() {
        const { handleSubmit, items } = this.props
        const groupedItems = _.groupBy(items || [], (item) => item.itemName.toLowerCase())
        const itemTypes = Object.keys(groupedItems).map(item => {
            return {
                key: item,
                text: item.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                    return letter.toUpperCase()
                })
            }
        })
        return (
            <form onSubmit={handleSubmit} noValidate>
                <Stack
                    horizontal={true}
                    tokens={{ childrenGap: 15 }}
                >
                    <Field
                        iconProps={{ iconName: 'Search' }}
                        name="item"
                        placeholder="Filter By Item"
                        options={[
                            {
                                key: "all",
                                text: "All"
                            },
                            ...itemTypes
                        ]}
                        component={FormHepler.RenderDropDown}
                    />
                </Stack>
            </form>
        )
    }
}

const ItemSearchFormMapped = reduxForm({
    form: "itemSearchForm",
    enableReinitialize: true,
})(ItemSearchForm)

const getInitialForm = () => {
    return {
        item: {
            key: "all",
            text: "All"
        }
    }
}

const mapStateToProps = ({ meta }) => ({ items: meta.items, initialValues: getInitialForm() })

const mapDispatchToProps = () => ({
    change
})

export default connect(mapStateToProps, mapDispatchToProps)(ItemSearchFormMapped)