import _ from "lodash"

export const required = (value) => {
  if (Array.isArray(value)) {
    if (value.length > 0) {
      return undefined
    }
    return "required"
  }
  return (!value && isNaN(value)) ? "required" : (_.trim((value + "") || "") ? undefined : "required")
}

export const checkForDuplicate = (list, key = "Name", defaultValue = "") => (
  value
) => {
  const recs = list.filter(
    (item) => item[key] === value && value !== defaultValue
  )
  return recs.length ? "duplicate" : undefined
}

export const maxLength = (length) => {
  return (value) => {
    return value.length > length ? "maxlength" : undefined
  }
}

export const minLength = (length) => {
  return (value) => {
    return ((value && value.length) || 0) < length ? "minlength" : undefined
  }
}

export const checkConfirmPassword = (value, allValues) => {
  return allValues.newPassword !== value ? "confirmPasswordMismatch" : undefined;
}

export const oldPasswordSame = (value, allValues) => {
  return allValues.oldPassword === value ? "oldPasswordSame" : undefined;
}
