import { IconButton } from "@fluentui/react"

export const getColumns = (onAction) => {
    return [
        {
            key: "itemName",
            name: "Item Name",
            fieldName: "itemName",
            data: 'string',
        },
        {
            key: "size",
            name: "Item Size",
            fieldName: "size",
            data: 'string',
            minWidth: 200,
        },
        {
            key: "grade",
            name: "Item Grade",
            fieldName: "grade",
            data: 'string',
        },
        {
            key: "make",
            name: "Make",
            fieldName: "make",
            data: 'string',
        },
        {
            key: "balanceStock",
            name: "Balance Stock",
            fieldName: "balanceStock",
            data: 'string',
        },
        {
            key: "action",
            name: "Actions",
            fieldName: "actions",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item, index, column) => {
                return (
                    <>
                        <IconButton
                            iconProps={{ iconName: 'Edit' }}
                            onClick={() => onAction("edit", item)}
                        />
                        <IconButton
                            iconProps={{ iconName: 'History' }}
                            onClick={() => onAction("history", item)}
                        />
                    </>
                )
            }
        },
    ]
}