import React from 'react'
import dayjs from 'dayjs';
import { connect } from "react-redux"
import { formValueSelector } from "redux-form"
import { PageHeader } from '../../shared/pageHeader';
import { getItemList } from '../../../store/actions/metaActions'
import { reportStockBalance, reportStockBalanceReset } from "../../../store/actions/reportActions"
import ItemGradeSearchForm from '../../forms/itemGradeSearchForm';
import ItemStockBalanceReport from '../itemStockBalanceReport';
import "./scss/clientReport.scss"

class ClosingStockReportPage extends React.Component {

    state = {
        type: {
            key: "all",
            text: "All"
        },
        grade: {
            key: "all",
            text: "All"
        },
        closingDate: dayjs().format("YYYY-MM-DD"),
        canDownload: false
    }

    componentDidMount() {
        const { getItemList, reportStockBalanceReset } = this.props
        reportStockBalanceReset()
        getItemList()
    }

    componentDidUpdate(prevProps, prevState) {
        const { type, grade, closingDate } = this.state
        const { itemsStockBalance, reportStockBalanceReset } = this.props

        if (this.props.type.key && this.props.type?.key !== type?.key) {
            this.setState({
                type: this.props.type,
                canDownload: false
            })
            reportStockBalanceReset()
        }

        if (this.props.grade.key && grade?.key !== this.props.grade?.key) {
            this.setState({
                grade: this.props.grade,
                canDownload: false,
            })
            reportStockBalanceReset()
        }

        if (this.props.closingDate && closingDate !== dayjs(this.props.closingDate).format("YYYY-MM-DD")) {
            this.setState({
                closingDate: dayjs(this.props.closingDate || new Date()).format("YYYY-MM-DD"),
                canDownload: false,
            })
            reportStockBalanceReset()
        }

        if (prevProps.itemsStockBalance?.requesting &&
            !itemsStockBalance.requesting) {
            this.setState({
                canDownload: true
            })
        }
    }

    onSearch = () => {
        const { type, grade, closingDate } = this.state
        const { reportStockBalance } = this.props
        reportStockBalance({
            type: type.key,
            grade: grade.key,
            date: closingDate
        })
    }

    onPrint = () => {
        window.print()
    }

    render() {
        const { session, itemsStockBalance } = this.props
        const userAccess = session.user.details.access
        return (
            <>
                <PageHeader
                    title="Stock Closing Report"
                    actions={[]}
                    searchComponent={
                        <ItemGradeSearchForm
                            userAccess={userAccess}
                            onSubmit={null}
                            onPrint={this.onPrint}
                            canDownload={this.state.canDownload}
                            onSearch={this.onSearch}
                        />
                    }
                />
                <ItemStockBalanceReport
                    date={this.state.closingDate}
                    items={itemsStockBalance?.list?.data || []}
                />
            </>
        )
    }
}

const selector = formValueSelector("itemGradeSearchForm")

const mapStateToProps = ({ session, items, reports, ...state }) => ({
    session,
    items,
    type: selector(state, 'type'),
    grade: selector(state, 'grade'),
    closingDate: selector(state, 'closingDate'),
    itemsStockBalance: reports.itemStockBalance || {}
})
const mapDispatchToProps = () => ({
    getItemList,
    reportStockBalance,
    reportStockBalanceReset
})

export default connect(mapStateToProps, mapDispatchToProps())(ClosingStockReportPage)