import React from "react"
import { connect } from "react-redux"
import { NavBar } from "../shared/navBar"
import PublicNavigation from "../../navigation/publicNavigation"
import PrivateNavigation from "../../navigation/privateNavigation"

class AppContainer extends React.Component {
    render() {
        const { session } = this.props
        return (
            <div className="full-height">
                <NavBar />
                <div className={"page-content"}>
                    {!session.user && <PublicNavigation />}
                    {session.user && <PrivateNavigation />}
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ session }) => ({
    session
})

export default connect(mapStateToProps)(AppContainer);