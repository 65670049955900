import { IconButton } from "@fluentui/react"

export const getColumns = (onAction) => {
    return [
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            data: 'string',
        },
        {
            key: "email",
            name: "Email",
            fieldName: "email",
            data: 'string',
            minWidth: 200,
        },
        {
            key: "phoneNumber",
            name: "Phone Number",
            fieldName: "phoneNumber",
            data: 'string',
            minWidth: 200,
        },
        {
            key: "access",
            name: "Access",
            fieldName: "access",
            data: 'string',
            onRender: (item, index, column) => {
                return item.access === "SA" ? "Admin" : "Employee"
            }
        },
        {
            key: "action",
            name: "Actions",
            fieldName: "actions",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item, index, column) => {
                return (
                    <IconButton
                        iconProps={{ iconName: 'Edit' }}
                        onClick={() => onAction("edit", item)}
                    />
                )
            }
        },
    ]
}