import React from "react"
import dayjs from "dayjs"
import { connect } from "react-redux"
import { formValueSelector } from "redux-form"
import { PageHeader } from "../../shared/pageHeader"
import StockSearchForm from "../../forms/stockSearchForm"
import { getClientList } from "../../../store/actions/metaActions"
import { reportItems } from "../../../store/actions/reportActions"
import { List } from "../../shared/list"
import { itemHistoryColumns } from "./constants"

class ItemHistoryReport extends React.Component {

    state = {
        page: 1,
        client: "",
        startDate: dayjs().startOf("week").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
    }

    componentDidMount() {
        const { getClientList } = this.props
        getClientList()
        this.getItemHistory()
    }

    componentDidUpdate(prevProps, prevState) {
        const { startDate, endDate, client, } = this.state

        if (prevState.startDate !== startDate ||
            prevState.endDate !== endDate ||
            prevState.client !== client) {
            this.setState({
                page: 1,
            }, () => {
                this.getItemHistory()
            })
        }

        if (this.props.startDate && prevProps.startDate !== this.props.startDate) {
            this.setState({
                startDate: dayjs(this.props.startDate).format("YYYY-MM-DD")
            })
        }

        if (this.props.endDate && prevProps.endDate !== this.props.endDate) {
            this.setState({
                endDate: dayjs(this.props.endDate).format("YYYY-MM-DD")
            })
        }

        if (prevProps.client !== this.props.client) {
            this.setState({
                client: this.props.client
            })
        }
    }

    getItemHistory = () => {
        const { reportItems, match } = this.props
        if (match.params && match.params.id) {
            const { startDate, endDate, client, page } = this.state

            reportItems({
                page,
                startDate,
                endDate,
                client: (client && client.value) || "",
                item: match.params.id
            })
        }
    }

    onPageChange = (page) => {
        this.setState({
            page: page + 1
        }, () => {
            this.getItemHistory()
        })
    }

    onPageHeaderActionClick = (action, addtionalParams) => {
        const { history } = this.props
        if (action === "edit") {
            history.push(`/stocks/edit/${addtionalParams.id}`)
        }
    }

    render() {
        const { session, itemHistory } = this.props
        const userAccess = session.user.details.access

        return (
            <>
                <PageHeader
                    title="Item Report"
                    actions={[]}
                    searchComponent={
                        <StockSearchForm
                            forReport={true}
                            onSubmit={this.onTextFormSubmit}
                            userAccess={userAccess}
                            canDownload={false}
                        />
                    }
                />
                <List
                    pagination={(itemHistory && itemHistory.data && itemHistory.data.pagination) || {}}
                    columns={itemHistoryColumns(this.onPageHeaderActionClick)}
                    data={(itemHistory && itemHistory.data && itemHistory.data.data) || []}
                    isLoading={itemHistory && itemHistory.requesting}
                    onPageChange={this.onPageChange}
                    sortCol="stockDate"
                    sortOrder={false}
                />
            </>
        )
    }
}

const selector = formValueSelector("stockSearchForm")
const mapStateToProps = ({ session, reports, ...state }) => ({
    session,
    startDate: selector(state, 'startDate'),
    endDate: selector(state, "endDate"),
    client: selector(state, 'client'),
    itemHistory: reports.itemHistory || null
})

const mapDispatchToProps = () => ({
    getClientList,
    reportItems
})

export default connect(mapStateToProps, mapDispatchToProps())(ItemHistoryReport)