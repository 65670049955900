import React from 'react'
import { connect } from "react-redux"
import { Field, FieldArray, reduxForm, formValueSelector, change } from "redux-form"
import { Stack, PrimaryButton, DefaultButton } from "@fluentui/react"
import * as FormHepler from "./formHelper"
import * as Validations from "./validation"
import * as StockConstants from "../pages/stocks/constants"

class StockMultipleForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            count: 1
        }
    }

    componentDidUpdate(prevProps) {
        const { formStocks, items, change } = this.props;
        if (prevProps.formStocks !== formStocks) {
            formStocks?.forEach((stock, index) => {
                const currentRec = formStocks?.[index] || {};
                const oldRec = prevProps.formStocks?.[index] || {}
                if (currentRec && (currentRec?.entryType && currentRec?.entryType.key !== StockConstants.CASH_IN && currentRec?.entryType.key !== StockConstants.CASH_OUT) &&
                    (oldRec?.numberOfPieces !== currentRec?.numberOfPieces ||
                        oldRec?.rate !== currentRec?.rate ||
                        oldRec?.weight !== currentRec?.weight ||
                        oldRec?.itemId !== currentRec?.itemId ||
                        oldRec?.transportationCost !== currentRec?.transportationCost ||
                        oldRec?.taxable !== currentRec?.taxable)) {
                    if (currentRec?.itemId) {
                        const item = (currentRec?.itemId.value && items && items.find(item => item.id === currentRec?.itemId.value)) || null
                        if (item) {
                            const cost = (item.calculateByNop === 1 ? parseFloat(currentRec?.numberOfPieces * currentRec?.rate) : parseFloat(currentRec?.weight * currentRec?.rate))
                            change(`stocks[${index}].totalAmount`, cost + parseFloat(currentRec?.transportationCost))
                            if (currentRec?.taxable) {
                                change(`stocks[${index}].tax`, parseFloat(cost * 0.18))
                            } else {
                                change(`stocks[${index}].tax`, 0)
                            }
                        }
                    }
                }
            })
        }
    }

    renderStockForm = ({ fields }) => {
        const { clients, session, items, formStocks } = this.props
        const userAccess = session.user.details.access
        return (
            <>
                {fields.map((member, index) => {
                    return (
                        <Stack
                            className='stock-row'
                            tokens={{ childrenGap: 15 }}
                            key={`stock_${index}`}
                        >
                            <Stack
                                horizontal
                                tokens={{ childrenGap: 15 }}
                            >
                                <Field
                                    name={`${member}.entryType`}
                                    label="Type"
                                    required={true}
                                    component={FormHepler.RenderDropDown}
                                    validate={Validations.required}
                                    options={StockConstants.ENTRY_TYPES.filter(item => item.access.includes(userAccess))}
                                />
                                <Field
                                    name={`${member}.clientId`}
                                    label="Client"
                                    required={true}
                                    isSearchable={true}
                                    width={200}
                                    placeholder="Select Client"
                                    component={FormHepler.RenderSearchDropDown}
                                    validate={Validations.required}
                                    options={clients.map(client => ({
                                        value: client.id,
                                        label: client.clientName
                                    }))}
                                />
                                <Field
                                    name={`${member}.stockDate`}
                                    placeholder="Select Entry Date"
                                    component={FormHepler.RenderDatePicker}
                                    required={true}
                                    label="Entry Date"
                                    maxDate={new Date()}
                                />
                            </Stack>
                            {(formStocks[index].entryType && (formStocks[index].entryType.key === StockConstants.CASH_IN || formStocks[index].entryType.key === StockConstants.CASH_OUT) && (
                                <Stack
                                    horizontal
                                    tokens={{ childrenGap: 15 }}
                                >
                                    <Field
                                        name={`${member}.totalAmount`}
                                        label="Total Amount"
                                        type="number"
                                        component={FormHepler.RenderTextField}
                                    />
                                </Stack>
                            ))}
                            {(formStocks[index].entryType && formStocks[index].entryType.key !== StockConstants.CASH_IN && formStocks[index].entryType.key !== StockConstants.CASH_OUT && (
                                <>
                                    <Stack
                                        horizontal
                                        tokens={{ childrenGap: 15 }}
                                    >
                                        <Field
                                            name={`${member}.itemId`}
                                            label="Item"
                                            placeholder="Select Item"
                                            required={true}
                                            isSearchable={true}
                                            component={FormHepler.RenderSearchDropDown}
                                            validate={Validations.required}
                                            options={items.map(item => ({
                                                value: item.id,
                                                label: `${item.itemName}/${item.size}/${item.grade}/${item.make || ""}`
                                            }))}
                                        />
                                        <Field
                                            name={`${member}.numberOfPieces`}
                                            label="Number of Pieces"
                                            required={true}
                                            type="number"
                                            component={FormHepler.RenderTextField}
                                            validate={Validations.required}
                                        />
                                        <Field
                                            name={`${member}.weight`}
                                            label="Weight (Kgs)"
                                            required={true}
                                            type="number"
                                            component={FormHepler.RenderTextField}
                                            validate={Validations.required}
                                        />
                                    </Stack>
                                    <Stack
                                        horizontal
                                        tokens={{ childrenGap: 15 }}
                                    >
                                        <Field
                                            name={`${member}.vehicleNum`}
                                            label="Vehicle Number"
                                            component={FormHepler.RenderTextField}
                                        />
                                        <Field
                                            name={`${member}.rate`}
                                            label="Rate"
                                            type="number"
                                            component={FormHepler.RenderTextField}
                                        />
                                        <Field
                                            name={`${member}.transportationCost`}
                                            label="Transportation Cost"
                                            type="number"
                                            component={FormHepler.RenderTextField}
                                        />
                                        <Field
                                            name={`${member}.totalAmount`}
                                            label="Total Amount"
                                            type="number"
                                            readOnly={true}
                                            component={FormHepler.RenderTextField}
                                        />
                                    </Stack>
                                    <Stack
                                        horizontal
                                        tokens={{ childrenGap: 15 }}
                                    >
                                        <Field
                                            name={`${member}.taxable`}
                                            label="Taxable"
                                            component={FormHepler.RenderToggleControl}
                                        />
                                        <Field
                                            name={`${member}.tax`}
                                            label="Tax Amount"
                                            type="number"
                                            readOnly={true}
                                            component={FormHepler.RenderTextField}
                                        />
                                    </Stack>
                                </>))}
                            <Stack
                                horizontal
                                tokens={{ childrenGap: 15 }}
                                styles={{ root: { width: 500 } }}
                            >
                                <Field
                                    styles={{ root: { width: 500 } }}
                                    name={`${member}.remark`}
                                    label="Remark"
                                    multiline={true}
                                    resizable={false}
                                    component={FormHepler.RenderTextField}
                                />
                            </Stack>
                        </Stack>
                    )
                })}
                <Stack
                    tokens={{ childrenGap: 15 }}
                >
                    <div className="form-actions">
                        <DefaultButton type='button' onClick={() => fields.push({
                            rate: 0,
                            totalAmount: 0,
                            numberOfPieces: 0,
                            transportationCost: 0,
                            weight: 0,
                            entryType: StockConstants.ENTRY_TYPES[0],
                            taxable: false,
                            tax: 0
                        })}>
                            Add Stock Row
                        </DefaultButton>
                    </div>
                </Stack>
            </>
        )
    }

    render() {
        const { handleSubmit } = this.props
        return (
            <form onSubmit={handleSubmit} noValidate className="admin-forms">
                <Stack
                    tokens={{ childrenGap: 15 }}
                >
                    <FieldArray name="stocks" component={this.renderStockForm} />
                    <div className="form-actions">
                        <PrimaryButton type="submit">Add Stock</PrimaryButton>
                    </div>
                </Stack>
            </form>
        );
    }
}



const StockMultipleFormMapped = reduxForm({
    form: "stockMultipleForm",
    enableReinitialize: true,
})(StockMultipleForm)

const formSelector = formValueSelector("stockMultipleForm")

const mapStateToProps = ({ stocks, meta, session, ...state }) => ({
    stocks,
    clients: meta.clients,
    items: meta.items,
    formStocks: formSelector(state, "stocks"),
    session
})

const mapDispatchToProps = () => ({
    change
})

export default connect(mapStateToProps, mapDispatchToProps)(StockMultipleFormMapped)