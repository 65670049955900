import * as actions from "./actionTypes"

export const getCashbookList = (payload) => ({
    type: actions.CASHBOOK_LIST_REQUEST,
    payload
})

export const getCashbookListSuccess = (payload) => ({
    type: actions.CASHBOOK_LIST_SUCCESS,
    payload
})

export const getCashbookListError = (payload) => ({
    type: actions.CASHBOOK_LIST_ERROR,
    payload
})

export const addCashbook = (payload) => ({
    type: actions.CASHBOOK_ADD_REQUEST,
    payload
})

export const addCashbookSuccess = (payload) => ({
    type: actions.CASHBOOK_ADD_SUCCESS,
    payload
})

export const addCashbookError = (payload) => ({
    type: actions.CASHBOOK_ADD_ERROR,
    payload
})

export const getCashbook = (payload) => ({
    type: actions.CASHBOOK_GET_REQUEST,
    payload
})

export const getCashbookSuccess = (payload) => ({
    type: actions.CASHBOOK_GET_SUCCESS,
    payload
})

export const getCashbookError = (payload) => ({
    type: actions.CASHBOOK_GET_ERROR,
    payload
})

export const updateCashbook = (payload) => ({
    type: actions.CASHBOOK_UPDATE_REQUEST,
    payload
})

export const updateCashbookSuccess = (payload) => ({
    type: actions.CASHBOOK_UPDATE_SUCCESS,
    payload
})

export const updateCashbookError = (payload) => ({
    type: actions.CASHBOOK_UPDATE_ERROR,
    payload
})

export const approveCashBook = (payload) => ({
    type: actions.CASHBOOK_UPDATE_APPROVE_REQUEST,
    payload
})

export const approveCashBookSuccess = (payload) => ({
    type: actions.CASHBOOK_UPDATE_APPROVE_SUCCESS,
    payload
})

export const approveCashBookError = (payload) => ({
    type: actions.CASHBOOK_UPDATE_APPROVE_ERROR,
    payload
})