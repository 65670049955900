import { all, call, takeEvery, put, takeLatest } from "redux-saga/effects"
import CashbookService from "../../services/CashbookService"
import * as actions from "../actions/actionTypes"
import {
    getCashbookListSuccess,
    getCashbookListError,
    addCashbookSuccess,
    addCashbookError,
    getCashbookSuccess,
    getCashbookError,
    updateCashbookSuccess,
    updateCashbookError,
    approveCashBookSuccess,
    approveCashBookError
} from "../actions/cashbookActions"


function* list(data) {
    try {
        const ajax = yield call(CashbookService.list, data.payload)
        if (ajax.status === 200) {
            yield put(
                getCashbookListSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                getCashbookListError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            getCashbookListError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

function* add(data) {
    try {
        const ajax = yield call(CashbookService.add, data.payload)
        if (ajax.status === 201) {
            yield put(
                addCashbookSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                addCashbookError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            addCashbookError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

function* get(data) {
    try {
        const ajax = yield call(CashbookService.get, data.payload)
        if (ajax.status === 200) {
            yield put(
                getCashbookSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                getCashbookError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            getCashbookError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

function* update(data) {
    try {
        const ajax = yield call(CashbookService.update, data.payload)
        if (ajax.status === 200) {
            yield put(
                updateCashbookSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                updateCashbookError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            updateCashbookError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

function* approve(data) {
    try {
        const ajax = yield call(CashbookService.approve, data.payload)
        if (ajax.status === 200) {
            yield put(
                approveCashBookSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                approveCashBookError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            approveCashBookError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

export default function* cashbookSaga() {
    yield all([
        takeLatest(actions.CASHBOOK_LIST_REQUEST, list),
        takeEvery(actions.CASHBOOK_ADD_REQUEST, add),
        takeEvery(actions.CASHBOOK_GET_REQUEST, get),
        takeLatest(actions.CASHBOOK_UPDATE_REQUEST, update),
        takeLatest(actions.CASHBOOK_UPDATE_APPROVE_REQUEST, approve),
    ])
}