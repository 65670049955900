import { loader } from "./initialState"

const loaderReducer = (state = loader, action) => {
  if (action.type.includes("API_REQUEST") && !action.type.includes("_SELF_")) {
    return {
      requestedApis: state.requestedApis + 1,
    }
  } else if (action.type.includes("API_SUCCESS") || action.type.includes("API_ERROR")) {
    return {
      requestedApis: (state.requestedApis > 0 && state.requestedApis - 1) || 0,
    }
  }

  return state
}

export default loaderReducer;
