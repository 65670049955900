import React from "react"
import {
    ShimmeredDetailsList,
    SelectionMode,
    ConstrainMode,
    CheckboxVisibility
} from "@fluentui/react"
import { Pagination } from '@uifabric/experiments'
import "./list.scss"

class List extends React.Component {

    state = {
        sortCol: "",
        sortOrder: true,
    }

    componentDidMount() {
        const { sortCol, sortOrder } = this.props
        if (sortCol) {
            this.setState({
                sortCol,
                sortOrder: sortOrder
            })
        }
    }

    onColumnClick = (e, col) => {
        const { columns, isLoading } = this.props
        if (!isLoading) {
            const selectedColumn = columns.find(item => item.fieldName === col.fieldName)
            if (selectedColumn
                && selectedColumn.fieldName !== "srno"
                && selectedColumn.fieldName !== "actions") {
                const { sortCol, sortOrder } = this.state
                this.setState({
                    sortCol: col.fieldName,
                    sortOrder: col.fieldName === sortCol ? !sortOrder : true
                })
            }
        }
    }

    getUpdatedData = () => {
        const { data } = this.props
        const { sortOrder, sortCol } = this.state
        const sortedData = data.sort((itemA, itemB) => {
            let sort = 0
            if (sortCol) {
                let compareA = (itemA[sortCol] || "").toLowerCase()
                let compareB = (itemB[sortCol] || "").toLowerCase()
                if (sortCol.includes(".")) {
                    const sortColArr = sortCol.split(".")
                    compareA = (itemA[sortColArr[0]][sortColArr[1]] || "").toLowerCase()
                    compareB = (itemB[sortColArr[0]][sortColArr[1]] || "").toLowerCase()
                }
                if (compareA > compareB) sort = 1
                else if (compareA < compareB) sort = -1
                if (!sortOrder && sort !== 0) {
                    sort = sort === 1 ? - 1 : 1
                }
            }
            return sort
        })

        return sortedData.map((item, index) => (
            {
                ...item,
                srno: index + 1
            }
        ))
    }

    getColumns = () => {
        const { columns } = this.props
        const { sortOrder, sortCol } = this.state
        const newColumns = [
            {
                key: "srno",
                name: "Sr. No.",
                fieldName: "srno",
                minWidth: 50,
                maxWidth: 50,
                data: 'number',
                isSorted: false
            },
            ...columns.map(col => ({
                ...col,
                isSorted: false
            }))
        ]

        const selectedCol = newColumns.find(item => item.fieldName === sortCol)
        if (selectedCol) {
            selectedCol.isSorted = true
            selectedCol.isSortedDescending = !sortOrder
        }

        return newColumns
    }

    onPageChange = (props) => {
        const { onPageChange } = this.props
        if (onPageChange) {
            onPageChange(props)
        }
    }

    render() {
        const { isLoading, pagination } = this.props

        const shimmeredDetailsListProps = {
            renderedWindowsAhead: 0,
            renderedWindowsBehind: 0,
        }

        const rows = this.getUpdatedData() || []

        return (
            <div className="list">
                <div className={`list-container ${rows.length ? "min-height" : ""}`}>
                    <ShimmeredDetailsList
                        enableShimmer={isLoading}
                        shimmerLines={9}
                        onColumnHeaderClick={this.onColumnClick}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        selectionPreservedOnEmptyClick={true}
                        selectionMode={SelectionMode.none}
                        columns={this.getColumns()}
                        listProps={shimmeredDetailsListProps}
                        items={rows}
                        onShouldVirtualize={() => false}
                    />
                </div>
                {(rows.length === 0 && !isLoading && (
                    <div className="no-records">No Records Found</div>
                )) || null}
                {(rows.length && !isLoading && (
                    <div className="pagination">
                        <Pagination
                            selectedPageIndex={pagination.currentPage - 1 || 0}
                            pageCount={pagination.lastPage || 0}
                            onPageChange={this.onPageChange}
                            format
                            firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
                            previousPageIconProps={{ iconName: 'ChevronLeft' }}
                            nextPageIconProps={{ iconName: 'ChevronRight' }}
                            lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
                        />
                    </div>
                )) || null}
            </div>
        )
    }
}

export default List