import React from "react"
import { connect } from "react-redux"
import { getItemList } from "../../store/actions/metaActions"
import { PageHeader } from "../shared/pageHeader"
import { ItemsStockList } from "./reports"
import ItemSearchForm from "../forms/ItemSearchForm"

class DashboardPage extends React.Component {

    componentDidMount() {
        const { getItemList } = this.props
        getItemList()
    }

    onSubmit = () => {

    }

    render() {
        const { history } = this.props
        return (
            <>
                <PageHeader
                    title="Dashboard"
                    actions={[]}
                    searchComponent={
                        <ItemSearchForm
                            onSubmit={this.onSubmit}
                        />
                    }
                />
                <ItemsStockList history={history} />
            </>
        )
    }
}

const mapStateToProps = ({ items }) => ({ items })
const mapDispatchToProps = () => ({
    getItemList
})

export default connect(mapStateToProps, mapDispatchToProps())(DashboardPage)