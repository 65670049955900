import { all, call, put, takeLatest } from "redux-saga/effects";
import ReportService from "../../services/ReportService";
import { downloadFile } from "../../utils/common";
import * as actions from "../actions/actionTypes";
import {
  reportItemsStockSuccess,
  reportItemsStockError,
  reportClientsSuccess,
  reportClientsError,
  reportItemsSuccess,
  reportItemsError,
  reportItemSuccess,
  reportItemError,
  reportStockBalanceSuccess,
  reportStockBalanceError,
} from "../actions/reportActions";

function* itemsStock(data) {
  try {
    const ajax = yield call(ReportService.itemsStock, data.payload);
    if (ajax.status === 200) {
      yield put(
        reportItemsStockSuccess({
          status: ajax.status,
          ...ajax.data.response,
        })
      );
    } else {
      yield put(
        reportItemsStockError({
          status: ajax.status,
          errors: ajax.data.response,
        })
      );
    }
  } catch (error) {
    yield put(
      reportItemsStockError({
        status: error.response.status || 500,
        errors:
          (error.response &&
            error.response.data &&
            error.response.data.response) ||
          {},
        message: (error && error.message) || "Error. Please try again later",
      })
    );
  }
}

function* clientReport(data) {
  try {
    const ajax = yield call(ReportService.clientReport, data.payload);
    if (ajax.status === 200) {
      if (data.payload.type !== "excel") {
        yield put(
          reportClientsSuccess({
            status: ajax.status,
            ...ajax.data.response,
          })
        );
      } else {
        downloadFile(ajax);
        yield put(
          reportClientsSuccess({
            status: ajax.status,
            type: "excel",
          })
        );
      }
    } else {
      yield put(
        reportClientsError({
          status: ajax.status,
          errors: ajax.data.response,
        })
      );
    }
  } catch (error) {
    yield put(
      reportClientsError({
        status: error.response.status || 500,
        errors:
          (error.response &&
            error.response.data &&
            error.response.data.response) ||
          {},
        message: (error && error.message) || "Error. Please try again later",
      })
    );
  }
}

function* itemHistory(data) {
  try {
    const ajax = yield call(ReportService.itemHistory, data.payload);
    if (ajax.status === 200) {
      yield put(
        reportItemsSuccess({
          status: ajax.status,
          ...ajax.data.response,
        })
      );
    } else {
      yield put(
        reportItemsError({
          status: ajax.status,
          errors: ajax.data.response,
        })
      );
    }
  } catch (error) {
    yield put(
      reportItemsError({
        status: error.response.status || 500,
        errors:
          (error.response &&
            error.response.data &&
            error.response.data.response) ||
          {},
        message: (error && error.message) || "Error. Please try again later",
      })
    );
  }
}

function* itemStockBalance(data) {
  try {
    const ajax = yield call(ReportService.itemStockBalanceReport, data.payload);
    if (ajax.status === 200) {
      yield put(
        reportStockBalanceSuccess({
          status: ajax.status,
          ...ajax.data.response,
        })
      );
    } else {
      yield put(
        reportStockBalanceError({
          status: ajax.status,
          errors: ajax.data.response,
        })
      );
    }
  } catch (error) {
    yield put(
      reportStockBalanceError({
        status: error.response.status || 500,
        errors:
          (error.response &&
            error.response.data &&
            error.response.data.response) ||
          {},
        message: (error && error.message) || "Error. Please try again later",
      })
    );
  }
}

function* itemReport(data) {
  try {
    const ajax = yield call(ReportService.itemReport, data.payload);
    if (ajax.status === 200) {
      if (data.payload.type !== "excel") {
        yield put(
          reportItemSuccess({
            status: ajax.status,
            ...ajax.data.response,
          })
        );
      } else {
        downloadFile(ajax);
        yield put(
          reportItemSuccess({
            status: ajax.status,
            type: "excel",
          })
        );
      }
    } else {
      yield put(
        reportItemError({
          status: ajax.status,
          errors: ajax.data.response,
        })
      );
    }
  } catch (error) {
    yield put(
      reportItemError({
        status: error.response.status || 500,
        errors:
          (error.response &&
            error.response.data &&
            error.response.data.response) ||
          {},
        message: (error && error.message) || "Error. Please try again later",
      })
    );
  }
}

export default function* reportSaga() {
  yield all([
    takeLatest(actions.REPORT_ITEMS_STOCK_REQUEST, itemsStock),
    takeLatest(actions.REPORT_CLIENTS_REQUEST, clientReport),
    takeLatest(actions.REPORT_ITEM_REQUEST, itemHistory),
    takeLatest(actions.REPORT_ITEM_STOCK_REQUEST, itemReport),
    takeLatest(actions.REPORT_ITEM_STOCK_BALANCE_REQUEST, itemStockBalance),
  ]);
}
