import React from "react"
import { connect } from "react-redux"
import { Field, reduxForm } from "redux-form"
import { Stack, PrimaryButton, MessageBarType, MessageBar } from "@fluentui/react"
import * as FormHepler from "./formHelper"
import * as Validations from "./validation"

class UserForm extends React.Component {

    constructor(props) {
        super(props)

        this.phoneMinLength = Validations.minLength(10)
        this.phoneMaxLength = Validations.maxLength(10)
    }

    render() {
        const { handleSubmit, edit, users } = this.props
        const error = (users && users.error && users.errorBody && users.errorBody.errors && users.errorBody.errors) || ""
        return (
            <>
                <form onSubmit={handleSubmit} noValidate className="admin-forms">
                    {(error && error.error && (
                        <Stack
                            tokens={{ childrenGap: 15 }}
                            styles={{ root: { width: 300 } }}
                        >
                            <MessageBar className="errorBox ucwords" messageBarType={MessageBarType.error}>{error.error}</MessageBar>
                        </Stack>)) || null}
                    <Stack
                        tokens={{ childrenGap: 15 }}
                    >
                        <Stack
                            horizontal
                            tokens={{ childrenGap: 15 }}
                        >
                            <Field
                                name="name"
                                label="Name"
                                required={true}
                                component={FormHepler.RenderTextField}
                                validate={Validations.required}
                            />
                            <Field
                                name="email"
                                label="Email"
                                type="email"
                                required={true}
                                component={FormHepler.RenderTextField}
                                validate={Validations.required}
                            />
                            <Field
                                name="phoneNo"
                                label="Phone Number"
                                required={true}
                                prefix="+91"
                                type="number"
                                minLength="10"
                                maxLength="10"
                                component={FormHepler.RenderTextField}
                                validate={[Validations.required, this.phoneMinLength, this.phoneMaxLength]}
                            />
                        </Stack>
                        <Stack
                            horizontal
                            tokens={{ childrenGap: 15 }}
                        >
                            <Field
                                name="isAdmin"
                                label="Admin Access"
                                onText="Full Access"
                                offText="Restricted Access"
                                component={FormHepler.RenderToggleControl}
                            />
                        </Stack>
                        <div className="form-actions">
                            <PrimaryButton type="submit">{edit ? "Update" : "Add"} User</PrimaryButton>
                        </div>
                    </Stack>
                </form>
            </>
        )
    }
}

const UserFormMapped = reduxForm({
    form: "userForm",
    enableReinitialize: true,
})(UserForm)

const getInitialForm = (record) => {
    return {
        name: (record && record.name) || "",
        email: (record && record.email) || "",
        phoneNo: (record && record.phoneNumber) || "",
        isAdmin: (record && record.access === "SA") || false
    }
}

const mapStateToProps = ({ users }) => ({
    users,
    initialValues: getInitialForm(users.record || null)
})

export default connect(mapStateToProps)(UserFormMapped)