import * as actions from "./actionTypes"

export const getClientList = (payload) => ({
    type: actions.META_CLIENT_LIST_REQUEST,
    payload
})

export const getClientListSuccess = (payload) => ({
    type: actions.META_CLIENT_LIST_SUCCESS,
    payload
})

export const getClientListError = (payload) => ({
    type: actions.META_CLIENT_LIST_ERROR,
    payload
})

export const getItemList = (payload) => ({
    type: actions.META_ITEM_LIST_REQUEST,
    payload
})

export const getItemListSuccess = (payload) => ({
    type: actions.META_ITEM_LIST_SUCCESS,
    payload
})

export const getItemListError = (payload) => ({
    type: actions.META_ITEM_LIST_ERROR,
    payload
})