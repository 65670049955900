import React from "react"
import { Field, reduxForm } from "redux-form"
import { Stack, PrimaryButton } from "@fluentui/react"
import * as FormHepler from "./formHelper"
import * as Validations from "./validation"

class LoginForm extends React.Component {

    constructor(props) {
        super(props)

        this.passwordMinLength = Validations.minLength(6);
        this.phoneMinLength = Validations.minLength(10);
        this.phoneMaxLength = Validations.maxLength(10);
    }

    render() {
        const { handleSubmit } = this.props
        return (
            <form onSubmit={handleSubmit} noValidate>
                <Stack
                    tokens={{ childrenGap: 15 }}
                    styles={{ root: { width: 300 } }}
                >
                    <Field
                        name="companyName"
                        label="Company"
                        required={true}
                        component={FormHepler.RenderTextField}
                        validate={Validations.required}
                    />
                    <Field
                        name="phoneNo"
                        label="Phone Number"
                        required={true}
                        prefix="+91"
                        type="number"
                        minLength="10"
                        maxLength="10"
                        component={FormHepler.RenderTextField}
                        validate={[Validations.required, this.phoneMinLength, this.phoneMaxLength]}
                    />
                    <Field
                        name="password"
                        label="Password"
                        type="password"
                        required={true}
                        canRevealPassword={true}
                        minLength="6"
                        component={FormHepler.RenderTextField}
                        validate={[Validations.required, this.passwordMinLength]}
                    />
                    <PrimaryButton type="submit">Login</PrimaryButton>
                </Stack>
            </form>
        )
    }
}

const LoginFormMapped = reduxForm({
    form: "loginForm",
    enableReinitialize: true,
})(LoginForm)

export default LoginFormMapped