import * as actions from "./actionTypes"

export const login = (payload) => ({
  type: actions.LOGIN_REQUEST,
  payload,
})

export const loginSuccess = (payload) => ({
  type: actions.LOGIN_SUCCESS,
  payload,
})

export const loginFail = (payload) => ({
  type: actions.LOGIN_ERROR,
  payload,
})

export const me = (payload) => ({
  type: actions.ME_REQUEST,
  payload
})

export const meSuccess = (payload) => ({
  type: actions.ME_SUCCESS,
  payload
})

export const meFail = (payload) => ({
  type: actions.ME_ERROR,
  payload
})

export const logout = () => ({
  type: actions.LOGOUT_USER
})

export const getUserList = (payload) => ({
  type: actions.USER_LIST_REQUEST,
  payload
})

export const getUserListSuccess = (payload) => ({
  type: actions.USER_LIST_SUCCESS,
  payload
})

export const getUserListError = (payload) => ({
  type: actions.USER_LIST_ERROR,
  payload
})

export const addUser = (payload) => ({
  type: actions.USER_ADD_REQUEST,
  payload
})

export const addUserSuccess = (payload) => ({
  type: actions.USER_ADD_SUCCESS,
  payload
})

export const addUserError = (payload) => ({
  type: actions.USER_ADD_ERROR,
  payload
})

export const getUser = (payload) => ({
  type: actions.USER_GET_REQUEST,
  payload
})

export const getUserSuccess = (payload) => ({
  type: actions.USER_GET_SUCCESS,
  payload
})

export const getUserError = (payload) => ({
  type: actions.USER_GET_ERROR,
  payload
})

export const updateUser = (payload) => ({
  type: actions.USER_UPDATE_REQUEST,
  payload
})

export const updateUserSuccess = (payload) => ({
  type: actions.USER_UPDATE_SUCCESS,
  payload
})

export const updateUserError = (payload) => ({
  type: actions.USER_UPDATE_ERROR,
  payload
})

export const updateUserPassword = (payload) => ({
  type: actions.YOUR_UPDATE_PASSWORD_REQUEST,
  payload
})

export const updateUserPasswordSuccess = (payload) => ({
  type: actions.YOUR_UPDATE_PASSWORD_SUCCESS,
  payload
})

export const updateUserPasswordError = (payload) => ({
  type: actions.YOUR_UPDATE_PASSWORD_ERROR,
  payload
})