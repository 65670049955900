import React from "react"
import { connect } from "react-redux"
import { IconButton } from "@fluentui/react"
import dayjs from "dayjs"
import * as stockContants from "../../../components/pages/stocks/constants"

class ClientReport extends React.Component {

    onAction = (action, item) => {
        if (this.props.onAction) {
            this.props.onAction(action, item)
        }
    }

    render() {
        const { clientReport = {} } = this.props
        const { data = null } = clientReport
        let openingBalance = 0
        let totalAmount = 0
        let totalPayment = 0
        if (data) {
            openingBalance = parseFloat(data.openingBalance) + parseFloat(data.amount) - parseFloat(data.payment)
        }
        totalAmount += openingBalance

        data?.stocks && data.stocks.forEach(item => {
            if (item.entryType === stockContants.STOCK_OUT || item.entryType === stockContants.CASH_OUT) {
                totalAmount += parseFloat(item.totalAmount)
            } else {
                totalPayment += parseFloat(item.totalAmount)
            }
        })

        return (
            <div className="client-report" id="client_report_print">
                {
                    (data && (
                        <>
                            <div className="report-hdr">
                                <div className="data-row">
                                    <div className="label">
                                        Client:
                                    </div>
                                    <div className="value">
                                        {data?.client?.clientName}
                                    </div>
                                </div>
                                <div className="data-row">
                                    <div className="label">
                                        Start Date:
                                    </div>
                                    <div className="value">
                                        {dayjs(data.startDate, "YYYY-MM-DD").format("DD MMM, YYYY")}
                                    </div>
                                </div>
                                <div className="data-row">
                                    <div className="label">
                                        End Date:
                                    </div>
                                    <div className="value">
                                        {dayjs(data.endDate, "YYYY-MM-DD").format("DD MMM, YYYY")}
                                    </div>
                                </div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Item</th>
                                        <th>Nos</th>
                                        <th>Weight</th>
                                        <th>Rate</th>
                                        <th>Transport</th>
                                        <th>Amount</th>
                                        <th>Payment</th>
                                        <th style={{
                                            width: '200px'
                                        }}>Remarks</th>
                                        <th className="no-print"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Opening Balance</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ textAlign: "center" }}>{openingBalance}</td>
                                        <td></td>
                                        <td></td>
                                        <td className="no-print"></td>
                                    </tr>
                                    {data.stocks?.map(item => (
                                        <tr key={item.id}>
                                            <td style={{ textAlign: "center" }}>
                                                {dayjs(item.stockDate, "YYYY-MM-DD").format("DD MMM, YYYY")}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {(item.item && `${item.item.itemName}/${item.item.size}/${item.item.grade}`) || "-"}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {(item.entryType.includes("STOCK") && item.numberOfPieces) || "-"}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {(item.entryType.includes("STOCK") && item.weight) || "-"}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {(item.entryType.includes("STOCK") && item.rate) || "-"}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {(item.entryType.includes("STOCK") && item.transportationCost) || "-"}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {((item.entryType === stockContants.STOCK_OUT || item.entryType === stockContants.CASH_OUT) && item.totalAmount) || 0}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {((item.entryType === stockContants.STOCK_IN || item.entryType === stockContants.CASH_IN) && item.totalAmount) || 0}
                                            </td>
                                            <td>
                                                {item.remark || ""}
                                            </td>
                                            <td style={{ textAlign: "center" }} className="no-print">
                                                <IconButton
                                                    iconProps={{ iconName: 'Edit' }}
                                                    onClick={() => this.onAction("edit", item)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan={6} style={{ textAlign: "right", fontWeight: "bold" }}>Total:</td>
                                        <td style={{ textAlign: "center", fontWeight: "bold" }}>{parseFloat(totalAmount).toFixed(2)}</td>
                                        <td style={{ textAlign: "center", fontWeight: "bold" }}>{parseFloat(totalPayment).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7} style={{ textAlign: "right", fontWeight: "bold" }}>Total Balance:</td>
                                        <td style={{ textAlign: "center", fontWeight: "bold" }}>{parseFloat(parseFloat(totalPayment) - parseFloat(totalAmount)).toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                    )) || null
                }
            </div>
        )
    }
}

const mapStateToProps = ({ reports }) => ({
    clientReport: reports.clientReport
})

export default connect(mapStateToProps)(ClientReport)