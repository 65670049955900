import DashboardPage from "../components/pages/dashboardPage"
import StockListPage from "../components/pages/stocks/stockListPage"
import StockAddEditPage from "../components/pages/stocks/stockAddEditPage"
import ClientListPage from "../components/pages/clients/clientListPage"
import ClientAddEditPage from "../components/pages/clients/clientAddEditPage"
import UserListPage from "../components/pages/users/userListPage"
import UserAddEditPage from "../components/pages/users/userAddEditPage"
import CashbookListPage from "../components/pages/cashbook/cashbookListPage"
import CashbookAddEditPage from "../components/pages/cashbook/cashbookAddEditPage"
import ItemAddEditPage from "../components/pages/items/itemAddEditPage"
import ItemListPage from "../components/pages/items/itemListPage"
import LicensePage from "../components/pages/licensePage"
import ClientReportPage from "../components/pages/reports/clientReportPage"
import ItemHistoryReport from "../components/pages/reports/itemHistoryReport"
import ItemReportPage from "../components/pages/reports/itemReportPage"
import ClosingStockReportPage from "../components/pages/reports/closingStockReportPage"

export const menus = [
    {
        name: "Dashboard",
        key: "dashboard",
        route: "/",
        url: "/",
        component: DashboardPage,
        icon: "ViewDashboard",
        access: ["SA"],
    },
    {
        name: "Users",
        component: UserListPage,
        route: "/users",
        url: "/users",
        key: "users",
        icon: "Group",
        access: ["SA"],
        subRoutes: [
            {
                route: "/users/add",
                component: UserAddEditPage
            },
            {
                route: "/users/edit/:id",
                component: UserAddEditPage
            }
        ]
    },
    {
        name: "Clients",
        component: ClientListPage,
        route: "/clients",
        url: "/clients",
        key: "clients",
        icon: "PageHeader",
        access: ["SA"],
        subRoutes: [
            {
                route: "/clients/add",
                component: ClientAddEditPage
            },
            {
                route: "/clients/edit/:id",
                component: ClientAddEditPage
            }
        ]
    },
    {
        name: "Items",
        component: ItemListPage,
        route: "/items",
        url: "/items",
        key: "items",
        icon: "Toolbox",
        access: ["SA"],
        subRoutes: [
            {
                route: "/items/add",
                component: ItemAddEditPage
            },
            {
                route: "/items/edit/:id",
                component: ItemAddEditPage
            },
            {
                route: "/items/history/:id",
                component: ItemHistoryReport
            }
        ]
    },
    {
        name: "Stocks",
        component: StockListPage,
        route: "/stocks",
        url: "/stocks",
        key: "stocks",
        icon: "Package",
        access: ["SA", "EMP"],
        subRoutes: [
            {
                route: "/stocks/add",
                component: StockAddEditPage
            },
            {
                route: "/stocks/edit/:id",
                component: StockAddEditPage
            }
        ]
    },
    {
        name: "CashBooks",
        component: CashbookListPage,
        route: "/cashbooks",
        url: "/cashbooks",
        key: "cashbooks",
        icon: "MONEY",
        access: ["SA", "EMP"],
        subRoutes: [
            {
                route: "/cashbooks/add",
                component: CashbookAddEditPage
            },
            {
                route: "/cashbooks/edit/:id",
                component: CashbookAddEditPage
            }
        ]
    },
    {
        name: "Report",
        key: "reports",
        access: ["SA"],
        links: [
            {
                name: 'Client',
                icon: 'Chart',
                route: '/reports/client',
                key: "reports-client",
                component: ClientReportPage,
            },
            {
                name: 'Item',
                icon: 'Chart',
                route: '/reports/item',
                key: "reports-item",
                component: ItemReportPage,
            },
            {
                name: 'Stock Closing',
                icon: 'Chart',
                route: '/reports/stock-closing',
                key: "reports-stock-closing",
                component: ClosingStockReportPage,
            }
        ]
    },
    {
        name: "License",
        component: LicensePage,
        route: "/license",
        url: "/license",
        key: "license",
        icon: "Commitments",
        access: ["SA"],
    },
]