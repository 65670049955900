import React from "react"
import { withRouter } from "react-router-dom"
import { Redirect, Route, Switch } from "react-router-dom"
import LoginPage from "../components/pages/loginPage"

class PublicNavigation extends React.Component {
    render() {
        const { location } = this.props
        return (
            <Switch>
                <Route exact={true} path="/login" component={LoginPage} />
                <Redirect to={`/login${location.pathname !== "/login" && location.search !== "" ? `${location.search}` : ""}`} />
            </Switch>
        )
    }
}

export default withRouter(PublicNavigation);