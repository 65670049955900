import React from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import CashbookForm from "../../forms/cashbookForm";
import { PageHeader } from "../../shared/pageHeader";
import {
  addCashbook,
  getCashbook,
  updateCashbook,
} from "../../../store/actions/cashbookActions";

class CashbookAddEditPage extends React.Component {
  state = {
    edit: false,
    id: 0,
    formSubmitted: false,
  };

  onSubmit = (values) => {
    const { addCashbook, updateCashbook, cashbooks } = this.props;
    const { edit } = this.state;
    this.setState({
      formSubmitted: true,
    });

    const formPayload = {
      ...values,
      client: values.client.key,
      cashType: values.cashType.key,
      cashDate: dayjs(values.cashDate).format("YYYY-MM-DD"),
      remark: values.remark || "",
    };

    if (!edit) {
      addCashbook({
        ...formPayload,
      });
    } else {
      updateCashbook({
        ...formPayload,
        id: cashbooks.record.id,
      });
    }
  };

  componentDidMount() {
    const { match, getCashbook } = this.props;
    if (match.params && match.params.id) {
      this.setState(
        {
          id: parseInt(match.params.id),
          edit: true,
        },
        () => {
          getCashbook({
            id: match.params.id,
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { cashbooks, history } = this.props;
    const { formSubmitted } = this.state;
    if (
      prevProps.cashbooks.requesting &&
      !cashbooks.requesting &&
      !cashbooks.error &&
      formSubmitted
    ) {
      history.push("/cashbooks");
    }
  }

  render() {
    const { edit } = this.state;
    return (
      <>
        <PageHeader title={`${edit ? "Edit" : "Add"} Cashbooks`} />
        <CashbookForm onSubmit={this.onSubmit} edit={edit} />
      </>
    );
  }
}

const mapStateToProps = ({ cashbooks }) => ({ cashbooks });

const mapDispatchToProps = () => ({
  addCashbook,
  getCashbook,
  updateCashbook,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(CashbookAddEditPage);
