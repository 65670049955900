import React from "react"
import { connect } from "react-redux"
import UserForm from "../../forms/userForm"
import { PageHeader } from "../../shared/pageHeader"
import { getUser, addUser, updateUser } from "../../../store/actions/userActions"

class UserAddEditPage extends React.Component {

    state = {
        edit: false,
        id: 0,
        formSubmitted: false
    }

    componentDidMount() {
        const { match, getUser } = this.props
        if (match.params && match.params.id) {
            this.setState({
                id: parseInt(match.params.id),
                edit: true
            }, () => {
                getUser({
                    id: match.params.id
                })
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { users, history } = this.props
        const { formSubmitted } = this.state
        if (prevProps.users.requesting && !users.requesting && !users.error && formSubmitted) {
            history.push("/users")
        }
    }

    onSubmit = (values) => {
        const { addUser, updateUser, users } = this.props
        const { edit } = this.state
        this.setState({
            formSubmitted: true
        })

        if (!edit) {
            addUser(values)
        } else {
            updateUser({
                ...values,
                id: users.record.id
            })
        }
    }

    render() {
        const { edit } = this.state
        return (
            <>
                <PageHeader title={`${edit ? "Edit" : "Add"} Users`} />
                <UserForm onSubmit={this.onSubmit} edit={edit} />
            </>
        )
    }
}

const mapStateToProps = ({ users }) => ({
    users,
})

const mapDispatchToProps = () => ({
    getUser,
    addUser,
    updateUser
})

export default connect(mapStateToProps, mapDispatchToProps())(UserAddEditPage)