import React from "react"
import { connect } from "react-redux"
import { PageHeader } from "../shared/pageHeader"
import dayjs from "dayjs"

class LicensePage extends React.Component {
    render() {
        const { session } = this.props
        return (
            <>
                <PageHeader
                    title="License Details"
                    actions={[]}
                />
                <div>
                    <div className="data-row">
                        <div className="label">
                            Company Name:
                        </div>
                        <div className="value">
                            {session.user.details.license.companyName}
                        </div>
                    </div>
                    <div className="data-row">
                        <div className="label">
                            Start Date:
                        </div>
                        <div className="value">
                            {dayjs(session.user.details.license.startDate, "YYYY-MM-DD").format("DD MMM, YYYY")}
                        </div>
                    </div>
                    <div className="data-row">
                        <div className="label">
                            End Date:
                        </div>
                        <div className="value">
                            {dayjs(session.user.details.license.endDate, "YYYY-MM-DD").format("DD MMM, YYYY")}
                        </div>
                    </div>
                    <div className="data-row">
                        <div className="label">
                            License:
                        </div>
                        <div className="value">
                            {session.user.details.license.license}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ session }) => ({ session })

export default connect(mapStateToProps)(LicensePage)