import React from "react"
import { connect } from "react-redux"
import { Field, reduxForm, formValueSelector, change } from "redux-form"
import { Stack, PrimaryButton } from "@fluentui/react"
import dayjs from "dayjs"
import * as FormHepler from "./formHelper"
import * as Validations from "./validation"
import * as StockConstants from "../pages/stocks/constants"

class StockForm extends React.Component {

    componentDidUpdate(prevProps) {
        const { numberOfPieces, rate, transportationCost, change, entryType, weight, items, itemId, taxable } = this.props;
        if ((entryType && entryType.key !== StockConstants.CASH_IN && entryType.key !== StockConstants.CASH_OUT) &&
            (prevProps.numberOfPieces !== numberOfPieces ||
                prevProps.rate !== rate ||
                prevProps.weight !== weight ||
                prevProps.itemId !== itemId ||
                prevProps.transportationCost !== transportationCost ||
                prevProps.taxable !== taxable)) {

            if (itemId) {
                const item = (itemId.value && items && items.find(item => item.id === itemId.value)) || null
                if (item) {
                    const cost = (item.calculateByNop === 1 ? parseFloat(numberOfPieces * rate) : parseFloat(weight * rate))
                    change("totalAmount", cost + parseFloat(transportationCost))
                    if (taxable) {
                        change("tax", parseFloat(cost * 0.18))
                    } else {
                        change("tax", 0)
                    }
                }
            }
        }
    }

    render() {
        const { handleSubmit, edit, clients, entryType, session, items } = this.props
        const userAccess = session.user.details.access
        return (
            <form onSubmit={handleSubmit} noValidate className="admin-forms">
                <Stack
                    tokens={{ childrenGap: 15 }}
                >
                    <Stack
                        horizontal
                        tokens={{ childrenGap: 15 }}
                    >
                        <Field
                            name="entryType"
                            label="Type"
                            required={true}
                            component={FormHepler.RenderDropDown}
                            validate={Validations.required}
                            options={StockConstants.ENTRY_TYPES.filter(item => item.access.includes(userAccess))}
                        />
                        <Field
                            name="clientId"
                            label="Client"
                            required={true}
                            isSearchable={true}
                            width={200}
                            placeholder="Select Client"
                            component={FormHepler.RenderSearchDropDown}
                            validate={Validations.required}
                            options={clients.map(client => ({
                                value: client.id,
                                label: client.clientName
                            }))}
                        />
                        <Field
                            name="stockDate"
                            placeholder="Select Entry Date"
                            component={FormHepler.RenderDatePicker}
                            required={true}
                            label="Entry Date"
                            maxDate={new Date()}
                        />
                    </Stack>
                    {(entryType && (entryType.key === StockConstants.CASH_IN || entryType.key === StockConstants.CASH_OUT) && (
                        <Stack
                            horizontal
                            tokens={{ childrenGap: 15 }}
                        >
                            <Field
                                name="totalAmount"
                                label="Total Amount"
                                type="number"
                                component={FormHepler.RenderTextField}
                            />
                        </Stack>
                    ))}
                    {(entryType && entryType.key !== StockConstants.CASH_IN && entryType.key !== StockConstants.CASH_OUT && (
                        <>
                            <Stack
                                horizontal
                                tokens={{ childrenGap: 15 }}
                            >
                                <Field
                                    name="itemId"
                                    label="Item"
                                    placeholder="Select Item"
                                    required={true}
                                    isSearchable={true}
                                    component={FormHepler.RenderSearchDropDown}
                                    validate={Validations.required}
                                    options={items.map(item => ({
                                        value: item.id,
                                        label: `${item.itemName}/${item.size}/${item.grade}/${item.make || ""}`
                                    }))}
                                />
                                <Field
                                    name="numberOfPieces"
                                    label="Number of Pieces"
                                    required={true}
                                    type="number"
                                    component={FormHepler.RenderTextField}
                                    validate={Validations.required}
                                />
                                <Field
                                    name="weight"
                                    label="Weight (Kgs)"
                                    required={true}
                                    type="number"
                                    component={FormHepler.RenderTextField}
                                    validate={Validations.required}
                                />
                            </Stack>
                            <Stack
                                horizontal
                                tokens={{ childrenGap: 15 }}
                            >
                                <Field
                                    name="vehicleNum"
                                    label="Vehicle Number"
                                    component={FormHepler.RenderTextField}
                                />
                                <Field
                                    name="rate"
                                    label="Rate"
                                    type="number"
                                    component={FormHepler.RenderTextField}
                                />
                                <Field
                                    name="transportationCost"
                                    label="Transportation Cost"
                                    type="number"
                                    component={FormHepler.RenderTextField}
                                />
                                <Field
                                    name="totalAmount"
                                    label="Total Amount"
                                    type="number"
                                    readOnly={true}
                                    component={FormHepler.RenderTextField}
                                />
                            </Stack>
                            <Stack
                                horizontal
                                tokens={{ childrenGap: 15 }}
                            >
                                <Field
                                    name="taxable"
                                    label="Taxable"
                                    component={FormHepler.RenderToggleControl}
                                />
                                <Field
                                    name="tax"
                                    label="Tax Amount"
                                    type="number"
                                    readOnly={true}
                                    component={FormHepler.RenderTextField}
                                />
                            </Stack>
                        </>))}
                    <Stack
                        horizontal
                        tokens={{ childrenGap: 15 }}
                        styles={{ root: { width: 500 } }}
                    >
                        <Field
                            styles={{ root: { width: 500 } }}
                            name="remark"
                            label="Remark"
                            multiline={true}
                            resizable={false}
                            component={FormHepler.RenderTextField}
                        />
                    </Stack>
                    <div className="form-actions">
                        <PrimaryButton type="submit">{edit ? "Update" : "Add"} Stock</PrimaryButton>
                    </div>
                </Stack>
            </form >
        )
    }
}

const StockFormMapped = reduxForm({
    form: "stockForm",
    enableReinitialize: true,
})(StockForm)

const formSelector = formValueSelector("stockForm")

const getInitialForm = (record, clients, items) => {
    if (record && clients && items) {
        const entryType = (record && StockConstants.ENTRY_TYPES.find(item => item.key === record.entryType)) || null
        const client = (record && clients && clients.map(item => ({
            value: item.id,
            label: item.clientName
        })).find(item => item.value === record.client.id)) || null
        const item = (record && record.item && items && items.map(item => ({
            value: item.id,
            label: `${item.itemName}/${item.size}/${item.grade}/${item.make || ""}`
        })).find(item => item.value === record.item.id)) || null

        const stockDate = (record && dayjs(record.stockDate, "YYYY-MM-DD").toDate()) || new Date()
        return {
            ...record,
            clientId: client,
            entryType: entryType || StockConstants.ENTRY_TYPES[0],
            stockDate,
            itemId: item,
            rate: record.rate || 0,
            totalAmount: record.totalAmount || 0,
            numberOfPieces: record.numberOfPieces || 0,
            transportationCost: record.transportationCost || 0,
            weight: record.weight || 0,
            taxable: record.taxable || false,
            tax: record.tax || 0
        }
    } else {
        return {
            rate: 0,
            totalAmount: 0,
            numberOfPieces: 0,
            transportationCost: 0,
            weight: 0,
            entryType: StockConstants.ENTRY_TYPES[0],
            taxable: false,
            tax: 0
        }
    }
}

const mapStateToProps = ({ stocks, meta, session, ...state }) => ({
    stocks,
    initialValues: getInitialForm(
        stocks.record || null,
        meta.clients || null,
        meta.items || null
    ),
    clients: meta.clients,
    items: meta.items,
    numberOfPieces: formSelector(state, "numberOfPieces"),
    rate: formSelector(state, "rate"),
    transportationCost: formSelector(state, "transportationCost"),
    entryType: formSelector(state, "entryType"),
    weight: formSelector(state, "weight"),
    itemId: formSelector(state, "itemId"),
    taxable: formSelector(state, "taxable"),
    session
})

const mapDispatchToProps = () => ({
    change
})

export default connect(mapStateToProps, mapDispatchToProps)(StockFormMapped)