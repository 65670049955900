import React from "react";
import { connect } from "react-redux";
import { formValueSelector, change } from "redux-form";
import dayjs from "dayjs";
import { List } from "../../shared/list";
import { PageHeader } from "../../shared/pageHeader";
import { getColumns } from "./constants";
import {
  getStockList,
  downloadStocksExcel,
  approveStock,
  updateStockQuery,
} from "../../../store/actions/stockActions";
import StockSearchForm from "../../forms/stockSearchForm";
import { getClientList, getItemList } from "../../../store/actions/metaActions";
import { getUserList } from "../../../store/actions/userActions";
import * as stockContants from "../../../components/pages/stocks/constants";

class StockListPage extends React.Component {
  state = {
    page: 1,
    entryType: "",
    client: "",
    startDate: dayjs().startOf("week").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
    approveStock: false,
    onlyUnApproved: false,
    user: "",
  };

  componentDidMount() {
    const {
      getClientList,
      getItemList,
      stocks,
      change,
      meta,
      getUserList,
      session,
      users,
    } = this.props;

    const userAccess = session.user.details.access;
    getClientList();
    getItemList();
    this.getStockList();

    if (userAccess === "SA") {
      getUserList();
    }

    if (stocks?.query) {
      change(
        "stockSearchForm",
        "startDate",
        dayjs(
          stocks.query.startDate || this.state.startDate,
          "YYYY-MM-DD"
        ).toDate()
      );
      change(
        "stockSearchForm",
        "endDate",
        dayjs(stocks.query.endDate, "YYYY-MM-DD").toDate()
      );
      change(
        "stockSearchForm",
        "client",
        meta?.clients
          .map((item) => ({
            value: item.id,
            label: item.clientName,
          }))
          .find((item) => item.value === stocks?.query?.client) || null
      );
      change(
        "stockSearchForm",
        "entryType",
        stockContants.ENTRY_TYPES.find(
          (item) => item.key === stocks.query.entryType
        ) || null
      );
      change(
        "stockSearchForm",
        "onlyUnApproved",
        stocks.query.onlyUnApproved || false
      );
      change(
        "stockSearchForm",
        "user",
        users
          .map((item) => ({
            value: item.id,
            label: item.name,
          }))
          .find((item) => item.value === stocks.query.user) || null
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      page,
      startDate,
      endDate,
      client,
      entryType,
      approveStock,
      onlyUnApproved,
      user,
    } = this.state;
    const { stocks } = this.props;
    if (
      prevState.page !== page ||
      prevState.startDate !== startDate ||
      prevState.endDate !== endDate ||
      prevState.client !== client ||
      prevState.entryType !== entryType ||
      prevState.onlyUnApproved !== onlyUnApproved ||
      prevState.user !== user
    ) {
      this.props.updateStockQuery({
        ...this.props.stocks.query,
        page,
        startDate,
        endDate,
        entryType: (entryType && entryType.key) || "",
        client: (client && client.value) || "",
        onlyUnApproved: onlyUnApproved,
        user: (user && user.value) || "",
      });
    }

    if (
      prevProps.stocks.requesting === true &&
      stocks.requesting === false &&
      stocks.error === false &&
      approveStock === true
    ) {
      this.setState({
        approveStock: false,
      });
      this.getStockList();
    }

    if (
      prevProps.stocks?.query !== this.props.stocks.query &&
      !stocks.requesting
    ) {
      this.getStockList();
    }

    if (this.props.startDate && prevProps.startDate !== this.props.startDate) {
      this.setState({
        startDate: dayjs(this.props.startDate).format("YYYY-MM-DD"),
        page: 1,
      });
    }

    if (this.props.endDate && prevProps.endDate !== this.props.endDate) {
      this.setState({
        endDate: dayjs(this.props.endDate).format("YYYY-MM-DD"),
        page: 1,
      });
    }

    if (prevProps.client !== this.props.client) {
      this.setState({
        client: this.props.client,
        page: 1,
      });
    }

    if (prevProps.user !== this.props.user) {
      this.setState({
        user: this.props.user,
        page: 1,
      });
    }

    if (prevProps.onlyUnApproved !== this.props.onlyUnApproved) {
      this.setState({
        onlyUnApproved: this.props.onlyUnApproved,
        page: 1,
      });
    }

    if (this.props.entryType && prevProps.entryType !== this.props.entryType) {
      this.setState({
        entryType: this.props.entryType,
        page: 1,
      });
    }
  }

  onPageHeaderActionClick = (action, addtionalParams) => {
    const { history, approveStock } = this.props;
    if (action === "add") {
      history.push("/stocks/add");
    } else if (action === "edit") {
      history.push(`/stocks/edit/${addtionalParams.id}`);
    } else if (action === "approve") {
      this.setState({
        approveStock: true,
      });
      approveStock({
        id: addtionalParams.id,
      });
    }
  };

  onPageChange = (page) => {
    this.setState({
      page: page + 1,
    });
  };

  getStockList = () => {
    const { getStockList, stocks } = this.props;
    getStockList({
      ...stocks.query,
    });
  };

  onDownload = () => {
    const { downloadStocksExcel, stocks } = this.props;
    downloadStocksExcel({
      ...stocks.query,
    });
  };

  render() {
    const { stocks, session } = this.props;
    const userAccess = session.user.details.access;
    return (
      <>
        <PageHeader
          title="Stocks"
          actions={["add"]}
          onAction={this.onPageHeaderActionClick}
          searchComponent={
            <StockSearchForm
              canDownload={
                !stocks.requesting &&
                stocks.list &&
                stocks.list.data &&
                stocks.list.data.length
              }
              onDownload={this.onDownload}
              userAccess={userAccess}
              showOptionForUnApproved={true}
            />
          }
        />
        <List
          pagination={(stocks.list && stocks.list.pagination) || {}}
          columns={getColumns(this.onPageHeaderActionClick, userAccess)}
          data={(stocks.list && stocks.list.data) || []}
          isLoading={stocks.requesting}
          onPageChange={this.onPageChange}
          sortCol="stockDate"
          sortOrder={false}
        />
      </>
    );
  }
}

const selector = formValueSelector("stockSearchForm");
const mapStateToProps = ({ stocks, session, meta, ...state }) => ({
  stocks,
  session,
  startDate: selector(state, "startDate"),
  endDate: selector(state, "endDate"),
  client: selector(state, "client"),
  entryType: selector(state, "entryType"),
  onlyUnApproved: selector(state, "onlyUnApproved"),
  user: selector(state, "user"),
  users: state.users?.list?.data || [],
  meta,
});

const mapDispatchToProps = () => ({
  approveStock,
  change,
  downloadStocksExcel,
  getClientList,
  getItemList,
  getStockList,
  getUserList,
  updateStockQuery,
});

export default connect(mapStateToProps, mapDispatchToProps())(StockListPage);
