// Global
export const UPDATE_GLOBAL_ERROR = "UPDATE_GLOBAL_ERROR";
export const ADD = "ADD";
export const LIST = "LIST";
export const DETAILS = "DETAILS";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";

// Meta
export const META_CLIENT_LIST_REQUEST = "META_CLIENT_LIST_API_REQUEST";
export const META_CLIENT_LIST_SUCCESS = "META_CLIENT_LIST_API_SUCCESS";
export const META_CLIENT_LIST_ERROR = "META_CLIENT_LIST_API_ERROR";
export const META_ITEM_LIST_REQUEST = "META_ITEM_LIST_API_REQUEST";
export const META_ITEM_LIST_SUCCESS = "META_ITEM_LIST_API_SUCCESS";
export const META_ITEM_LIST_ERROR = "META_ITEM_LIST_API_ERROR";

// Auth
export const LOGIN_REQUEST = "LOGIN_API_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_API_SUCCESS";
export const LOGIN_ERROR = "LOGIN_API_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const ME_REQUEST = "ME_API_REQUEST";
export const ME_SUCCESS = "ME_API_SUCCESS";
export const ME_ERROR = "ME_API_ERROR";

// Clients
export const CLIENT_LIST_REQUEST = "CLIENT_LIST_SELF_API_REQUEST";
export const CLIENT_LIST_SUCCESS = "CLIENT_LIST_SELF_API_SUCCESS";
export const CLIENT_LIST_ERROR = "CLIENT_LIST_SELF_API_ERROR";
export const CLIENT_ADD_REQUEST = "CLIENT_ADD_API_REQUEST";
export const CLIENT_ADD_SUCCESS = "CLIENT_ADD_API_SUCCESS";
export const CLIENT_ADD_ERROR = "CLIENT_ADD_API_ERROR";
export const CLIENT_GET_REQUEST = "CLIENT_GET_API_REQUEST";
export const CLIENT_GET_SUCCESS = "CLIENT_GET_API_SUCCESS";
export const CLIENT_GET_ERROR = "CLIENT_GET_API_ERROR";
export const CLIENT_UPDATE_REQUEST = "CLIENT_UPDATE_API_REQUEST";
export const CLIENT_UPDATE_SUCCESS = "CLIENT_UPDATE_API_SUCCESS";
export const CLIENT_UPDATE_ERROR = "CLIENT_UPDATE_API_ERROR";

// Items
export const ITEM_LIST_REQUEST = "ITEM_LIST_SELF_API_REQUEST";
export const ITEM_LIST_SUCCESS = "ITEM_LIST_SELF_API_SUCCESS";
export const ITEM_LIST_ERROR = "ITEM_LIST_SELF_API_ERROR";
export const ITEM_ADD_REQUEST = "ITEM_ADD_API_REQUEST";
export const ITEM_ADD_SUCCESS = "ITEM_ADD_API_SUCCESS";
export const ITEM_ADD_ERROR = "ITEM_ADD_API_ERROR";
export const ITEM_GET_REQUEST = "ITEM_GET_API_REQUEST";
export const ITEM_GET_SUCCESS = "ITEM_GET_API_SUCCESS";
export const ITEM_GET_ERROR = "ITEM_GET_API_ERROR";
export const ITEM_UPDATE_REQUEST = "ITEM_UPDATE_API_REQUEST";
export const ITEM_UPDATE_SUCCESS = "ITEM_UPDATE_API_SUCCESS";
export const ITEM_UPDATE_ERROR = "ITEM_UPDATE_API_ERROR";

// Stocks
export const STOCK_LIST_REQUEST = "STOCK_LIST_SELF_API_REQUEST";
export const STOCK_LIST_SUCCESS = "STOCK_LIST_SELF_API_SUCCESS";
export const STOCK_LIST_ERROR = "STOCK_LIST_SELF_API_ERROR";
export const STOCK_ADD_REQUEST = "STOCK_ADD_API_REQUEST";
export const STOCK_ADD_SUCCESS = "STOCK_ADD_API_SUCCESS";
export const STOCK_ADD_ERROR = "STOCK_ADD_API_ERROR";
export const STOCK_GET_REQUEST = "STOCK_GET_API_REQUEST";
export const STOCK_GET_SUCCESS = "STOCK_GET_API_SUCCESS";
export const STOCK_GET_ERROR = "STOCK_GET_API_ERROR";
export const STOCK_UPDATE_REQUEST = "STOCK_UPDATE_API_REQUEST";
export const STOCK_UPDATE_SUCCESS = "STOCK_UPDATE_API_SUCCESS";
export const STOCK_UPDATE_ERROR = "STOCK_UPDATE_API_ERROR";
export const STOCK_DOWNLOAD_EXCEL_REQUEST = "STOCK_DOWNLOAD_EXCEL_API_REQUEST";
export const STOCK_DOWNLOAD_EXCEL_SUCCESS = "STOCK_DOWNLOAD_EXCEL_API_SUCCESS";
export const STOCK_DOWNLOAD_EXCEL_ERROR = "STOCK_DOWNLOAD_EXCEL_API_ERROR";
export const STOCK_UPDATE_APPROVE_REQUEST = "STOCK_UPDATE_APPROVED_API_REQUEST";
export const STOCK_UPDATE_APPROVE_SUCCESS = "STOCK_UPDATE_APPROVED_API_SUCCESS";
export const STOCK_UPDATE_APPROVE_ERROR = "STOCK_UPDATE_APPROVED_API_ERROR";
export const STOCK_UPDATE_QUERY = "STOCK_UPDATE_QUERY";

// Users
export const USER_LIST_REQUEST = "USER_LIST_SELF_API_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SELF_API_SUCCESS";
export const USER_LIST_ERROR = "USER_LIST_SELF_API_ERROR";
export const USER_ADD_REQUEST = "USER_ADD_API_REQUEST";
export const USER_ADD_SUCCESS = "USER_ADD_API_SUCCESS";
export const USER_ADD_ERROR = "USER_ADD_API_ERROR";
export const USER_GET_REQUEST = "USER_GET_API_REQUEST";
export const USER_GET_SUCCESS = "USER_GET_API_SUCCESS";
export const USER_GET_ERROR = "USER_GET_API_ERROR";
export const USER_UPDATE_REQUEST = "USER_UPDATE_API_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_API_SUCCESS";
export const USER_UPDATE_ERROR = "USER_UPDATE_API_ERROR";
export const YOUR_UPDATE_PASSWORD_REQUEST = "YOUR_UPDATE_PASSWORD_API_REQUEST";
export const YOUR_UPDATE_PASSWORD_SUCCESS = "YOUR_UPDATE_PASSWORD_API_SUCCESS";
export const YOUR_UPDATE_PASSWORD_ERROR = "YOUR_UPDATE_PASSWORD_API_ERROR";

// Cashbooks
export const CASHBOOK_LIST_REQUEST = "CASHBOOK_LIST_SELF_API_REQUEST";
export const CASHBOOK_LIST_SUCCESS = "CASHBOOK_LIST_SELF_API_SUCCESS";
export const CASHBOOK_LIST_ERROR = "CASHBOOK_LIST_SELF_API_ERROR";
export const CASHBOOK_ADD_REQUEST = "CASHBOOK_ADD_API_REQUEST";
export const CASHBOOK_ADD_SUCCESS = "CASHBOOK_ADD_API_SUCCESS";
export const CASHBOOK_ADD_ERROR = "CASHBOOK_ADD_API_ERROR";
export const CASHBOOK_GET_REQUEST = "CASHBOOK_GET_API_REQUEST";
export const CASHBOOK_GET_SUCCESS = "CASHBOOK_GET_API_SUCCESS";
export const CASHBOOK_GET_ERROR = "CASHBOOK_GET_API_ERROR";
export const CASHBOOK_UPDATE_REQUEST = "CASHBOOK_UPDATE_API_REQUEST";
export const CASHBOOK_UPDATE_SUCCESS = "CASHBOOK_UPDATE_API_SUCCESS";
export const CASHBOOK_UPDATE_ERROR = "CASHBOOK_UPDATE_API_ERROR";
export const CASHBOOK_UPDATE_APPROVE_REQUEST =
  "CASHBOOK_UPDATE_APPROVED_API_REQUEST";
export const CASHBOOK_UPDATE_APPROVE_SUCCESS =
  "CASHBOOK_UPDATE_APPROVED_API_SUCCESS";
export const CASHBOOK_UPDATE_APPROVE_ERROR =
  "CASHBOOK_UPDATE_APPROVED_API_ERROR";

// Reports
export const REPORT_ITEMS_STOCK_REQUEST = "REPORT_ITEMS_STOCK_API_REQUEST";
export const REPORT_ITEMS_STOCK_SUCCESS = "REPORT_ITEMS_STOCK_API_SUCCESS";
export const REPORT_ITEMS_STOCK_ERROR = "REPORT_ITEMS_STOCK_API_ERROR";
export const REPORT_CLIENTS_REQUEST = "REPORT_CLIENTS_API_REQUEST";
export const REPORT_CLIENTS_SUCCESS = "REPORT_CLIENTS_API_SUCCESS";
export const REPORT_CLIENTS_ERROR = "REPORT_CLIENTS_API_ERROR";
export const RESET_REPORT_CLIENT = "RESET_REPORT_CLIENT";
export const REPORT_ITEM_REQUEST = "REPORT_ITEM_SELF_API_REQUEST";
export const REPORT_ITEM_SUCCESS = "REPORT_ITEM_SELF_API_SUCCESS";
export const REPORT_ITEM_ERROR = "REPORT_ITEM_SELF_API_ERROR";
export const REPORT_ITEM_STOCK_RESET = "REPORT_ITEM_STOCK_RESET";
export const REPORT_ITEM_STOCK_REQUEST = "REPORT_ITEM_STOCK_API_REQUEST";
export const REPORT_ITEM_STOCK_SUCCESS = "REPORT_ITEM_STOCK_API_SUCCESS";
export const REPORT_ITEM_STOCK_ERROR = "REPORT_ITEM_STOCK_API_ERROR";
export const REPORT_ITEM_STOCK_BALANCE_REQUEST =
  "REPORT_ITEM_STOCK_BALANCE_API_REQUEST";
export const REPORT_ITEM_STOCK_BALANCE_SUCCESS =
  "REPORT_ITEM_STOCK_BALANCE_API_SUCCESS";
export const REPORT_ITEM_STOCK_BALANCE_ERROR =
  "REPORT_ITEM_STOCK_BALANCE_API_ERROR";
export const REPORT_ITEM_STOCK_BALANCE_RESET =
  "REPORT_ITEM_STOCK_BALANCE_API_RESET";
