import BaseService from "./BaseService"

class CashbookService {

    static async list(payload) {
        return await BaseService.callAPI(`cashbooks?page=${payload.page || 1}`, "GET", payload)
    }

    static async add(payload) {
        return await BaseService.callAPI("cashbooks", "PUT", payload)
    }

    static async get(payload) {
        return await BaseService.callAPI(`cashbooks/${payload.id}`, "GET", payload)
    }

    static async update(payload) {
        return await BaseService.callAPI(`cashbooks/${payload.id}`, "POST", payload)
    }

    static async approve(payload) {
        return await BaseService.callAPI(`cashbooks/${payload.id}/approve`, "POST", payload)
    }
}

export default CashbookService