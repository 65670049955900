import dayjs from 'dayjs'
import React from 'react'

class ItemStockBalanceReport extends React.Component {

    render() {
        const { items, date } = this.props
        return (items.length > 0 && (
            <div className="client-report" id="client_report_print">
                <div className="report-hdr">
                    <div className="data-row">
                        <div className="label">
                            As of Date:
                        </div>
                        <div className="value">
                            {dayjs(date).format('DD-MMM-YYYY')}
                        </div>
                    </div>
                </div>
                <table width='90%'>
                    <thead>
                        <tr>
                            <th width='25%'>Type</th>
                            <th width='25%'>Grade</th>
                            <th width='25%'>Size</th>
                            <th width='25%'>Closing Stock</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(item => {
                            return (
                                <tr>
                                    <td className='capitalise'>{item.itemName.toLowerCase()}</td>
                                    <td className='capitalise'>{item.grade.toLowerCase()}</td>
                                    <td className='capitalise'>{item.size.toLowerCase()}</td>
                                    <td>{`${(item.calculateByNop ? parseInt(item.balanceNop) : parseFloat(item.balanceSize)) + parseInt(item.purchasedStock)} ${item.calculateByNop ? "" : " Kgs"}`}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )) || null
    }
}

export default ItemStockBalanceReport