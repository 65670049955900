import React from "react";
import {
  TextField,
  DatePicker,
  mergeStyleSets,
  Dropdown,
  Toggle,
  Label,
} from "@fluentui/react";
import ReactSelect from "react-select";

const getErrorMessage = (error, inputProps) => {
  switch (error) {
    case "required":
      return `${inputProps.label} is required`;
    case "duplicate":
      return `Duplicate ${inputProps.label} found.`;
    case "maxlength":
      return `${inputProps.label} should contain ${inputProps.maxLength} ${
        inputProps.type === "number" ? "digits" : "character"
      }`;
    case "minlength":
      return `${inputProps.label} should contain ${inputProps.minLength} ${
        inputProps.type === "number" ? "digits" : "character"
      }`;
    case "confirmPasswordMismatch":
      return `${inputProps.label} doesn't match New Password.`;
    case "oldPasswordSame":
      return `${inputProps.label} should not match Current Password.`;
    default:
      return "";
  }
};

const datePickerControlClass = mergeStyleSets({
  control: {
    margin: "0",
    maxWidth: "300px",
  },
});

const dropdownStyles = mergeStyleSets({
  dropdown: { width: 175 },
});

const DayPickerStrings = {
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],

  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],

  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],

  shortDays: ["S", "M", "T", "W", "T", "F", "S"],

  goToToday: "Go to today",
  prevMonthAriaLabel: "Go to previous month",
  nextMonthAriaLabel: "Go to next month",
  prevYearAriaLabel: "Go to previous year",
  nextYearAriaLabel: "Go to next year",
  closeButtonAriaLabel: "Close date picker",
  monthPickerHeaderAriaLabel: "{0}, select to change the year",
  yearPickerHeaderAriaLabel: "{0}, select to change the month",
};

export const RenderTextField = ({
  meta: { touched, error },
  input,
  ...rest
}) => {
  return (
    <TextField
      {...rest}
      {...input}
      value={input.value}
      errorMessage={(touched && error && getErrorMessage(error, rest)) || ""}
    />
  );
};

export const RenderDatePicker = ({
  meta: { touched, error },
  input,
  placeholder,
  label,
  required,
  ...rest
}) => {
  return (
    <DatePicker
      className={datePickerControlClass.control}
      strings={DayPickerStrings}
      {...rest}
      label={label || ""}
      isRequired={required || false}
      placeholder={placeholder || ""}
      allowTextInput={false}
      value={input.value || new Date()}
      onSelectDate={(date) => input.onChange(date)}
      errorMessage={(touched && error && getErrorMessage(error, rest)) || ""}
    />
  );
};

export const RenderDropDown = ({
  meta: { touched, error },
  input,
  options,
  ...rest
}) => {
  return (
    <Dropdown
      {...rest}
      {...input}
      value={input.value}
      options={options}
      className={dropdownStyles.dropdown}
      selectedKey={(input.value && input.value.key) || ""}
      onChange={(e, item) => input.onChange(item)}
      errorMessage={(touched && error && getErrorMessage(error, rest)) || ""}
    />
  );
};

export const RenderToggleControl = ({
  meta: { touched, error },
  input,
  label,
  onText,
  offText,
  ...rest
}) => {
  return (
    <Toggle
      label={label}
      checked={input.value}
      onText={onText || ""}
      offText={offText || ""}
      onChange={(e, checked) => input.onChange(checked)}
      errorMessage={(touched && error && getErrorMessage(error, rest)) || ""}
    />
  );
};

export const RenderSearchDropDown = ({
  meta: { touched, error },
  required,
  label,
  input,
  options,
  ...rest
}) => {
  const searchDropDownStyles = {
    control: (provided, { selectProps: { width } }) => ({
      ...provided,
      width: width || 250,
      borderColor: (touched && error && "#a4373a") || "rgb(96, 94, 92)",
      borderRadius: 0,
      height: "32px",
      minHeight: "32px",
      "&:hover": {
        borderColor: (touched && error && "#a4373a") || "rgb(96, 94, 92)",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      color: "rgb(50, 49, 48)",
      height: "32px",
      minHeight: "32px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: (touched && error && "#a4373a") || "rgb(50, 49, 48)",
      fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI"',
      fontSize: "14px",
      fontWeight: "400",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      marginTop: "5px",
      marginBottom: "7px",
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "0px 8px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "rgb(50, 49, 48)",
      fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI"',
      fontSize: "14px",
      fontWeight: "400",
    }),
  };

  return (
    <div className="search-select-container">
      {(label && <Label required={required}>{label}</Label>) || null}
      <ReactSelect
        {...rest}
        {...input}
        styles={searchDropDownStyles}
        className="react-select"
        value={input.value}
        options={options}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
      />
    </div>
  );
};
