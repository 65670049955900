import * as actions from "./actionTypes";

export const getStockList = (payload) => ({
  type: actions.STOCK_LIST_REQUEST,
  payload,
});

export const getStockListSuccess = (payload) => ({
  type: actions.STOCK_LIST_SUCCESS,
  payload,
});

export const getStockListError = (payload) => ({
  type: actions.STOCK_LIST_ERROR,
  payload,
});

export const addStock = (payload) => ({
  type: actions.STOCK_ADD_REQUEST,
  payload,
});

export const addStockSuccess = (payload) => ({
  type: actions.STOCK_ADD_SUCCESS,
  payload,
});

export const addStockError = (payload) => ({
  type: actions.STOCK_ADD_ERROR,
  payload,
});

export const getStock = (payload) => ({
  type: actions.STOCK_GET_REQUEST,
  payload,
});

export const getStockSuccess = (payload) => ({
  type: actions.STOCK_GET_SUCCESS,
  payload,
});

export const getStockError = (payload) => ({
  type: actions.STOCK_GET_ERROR,
  payload,
});

export const updateStock = (payload) => ({
  type: actions.STOCK_UPDATE_REQUEST,
  payload,
});

export const updateStockSuccess = (payload) => ({
  type: actions.STOCK_UPDATE_SUCCESS,
  payload,
});

export const updateStockError = (payload) => ({
  type: actions.STOCK_UPDATE_ERROR,
  payload,
});

export const downloadStocksExcel = (payload) => ({
  type: actions.STOCK_DOWNLOAD_EXCEL_REQUEST,
  payload,
});

export const downloadStocksExcelSuccess = (payload) => ({
  type: actions.STOCK_DOWNLOAD_EXCEL_SUCCESS,
  payload,
});

export const downloadStocksExcelError = (payload) => ({
  type: actions.STOCK_DOWNLOAD_EXCEL_ERROR,
  payload,
});

export const approveStock = (payload) => ({
  type: actions.STOCK_UPDATE_APPROVE_REQUEST,
  payload,
});

export const approveStockSuccess = (payload) => ({
  type: actions.STOCK_UPDATE_APPROVE_SUCCESS,
  payload,
});

export const approveStockError = (payload) => ({
  type: actions.STOCK_UPDATE_APPROVE_ERROR,
  payload,
});

export const updateStockQuery = (payload) => ({
  type: actions.STOCK_UPDATE_QUERY,
  payload,
});
