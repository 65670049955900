import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { initializeIcons } from '@uifabric/icons'
import { configureStore } from "../store/configureStore"
import { AppLoader } from "./shared/appLoader"
import AppContainer from "./containers/appContainer"
import CacheBuster from "../utils/cacheBuster"
import "react-toastify/dist/ReactToastify.css"
import "../assets/scss/app.scss"

initializeIcons()
const store = configureStore()

function App() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }
        return (
          <Router>
            <Provider store={store}>
              <AppContainer />
              <AppLoader />
            </Provider>
          </Router>
        );
      }}
    </CacheBuster>
  );
}

export default App;
