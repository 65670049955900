import React from "react"
import { connect } from "react-redux"
import ClientForm from "../../forms/clientForm"
import { PageHeader } from "../../shared/pageHeader"
import { addClient, getClient, updateClient } from "../../../store/actions/clientActions"
import dayjs from "dayjs"

class ClientAddEditPage extends React.Component {

    state = {
        edit: false,
        id: 0,
        formSubmitted: false
    }

    onSubmit = (values) => {
        const { addClient, updateClient, clients } = this.props
        const { edit } = this.state
        this.setState({
            formSubmitted: true
        })
        if (!edit) {
            addClient({
                ...values,
                openingBalanceDate: dayjs(values.openingBalanceDate).format("YYYY-MM-DD")
            })
        } else {
            updateClient({
                ...values,
                openingBalanceDate: dayjs(values.openingBalanceDate).format("YYYY-MM-DD"),
                id: clients.record.id
            })
        }
    }

    componentDidMount() {
        const { match, getClient } = this.props
        if (match.params && match.params.id) {
            this.setState({
                id: parseInt(match.params.id),
                edit: true
            }, () => {
                getClient({
                    id: match.params.id
                })
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { clients, history } = this.props
        const { formSubmitted } = this.state
        if (prevProps.clients.requesting && !clients.requesting && !clients.error && formSubmitted) {
            history.push("/clients")
        }
    }

    render() {
        const { edit } = this.state
        return (
            <>
                <PageHeader title={`${edit ? "Edit" : "Add"} Clients`} />
                <ClientForm onSubmit={this.onSubmit} edit={edit} />
            </>
        )
    }
}

const mapStateToProps = ({ clients }) => ({ clients })

const mapDispatchToProps = () => ({
    addClient,
    getClient,
    updateClient
})

export default connect(mapStateToProps, mapDispatchToProps())(ClientAddEditPage)