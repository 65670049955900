import React from "react"
import { connect } from "react-redux"
import { formValueSelector } from "redux-form"
import dayjs from "dayjs"
import { DialogType } from "@fluentui/react"
import StockSearchForm from "../../forms/stockSearchForm"
import StockForm from "../../forms/stockForm"
import { PageHeader } from "../../shared/pageHeader"
import { Modal } from "../../shared/modal"
import { getStock, updateStock } from "../../../store/actions/stockActions"
import { getClientList, getItemList } from "../../../store/actions/metaActions"
import { reportClients, resetReportClient } from "../../../store/actions/reportActions"
import { ClientReport } from "./"
import "./scss/clientReport.scss"

class ClientReportPage extends React.Component {

    state = {
        formSubmitted: false,
        client: "",
        startDate: dayjs().startOf("week").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
        stockEditModalOpen: false,
    }

    componentDidMount() {
        const { getClientList, getItemList, resetReportClient } = this.props
        getClientList()
        getItemList()
        resetReportClient()
    }

    componentDidUpdate(prevProps, prevState) {
        const { stocks } = this.props
        const { startDate, endDate, client, formSubmitted } = this.state

        if (prevState.startDate !== startDate ||
            prevState.endDate !== endDate ||
            prevState.client !== client) {
            this.getClientReport()
        }

        if (this.props.startDate && prevProps.startDate !== this.props.startDate) {
            this.setState({
                startDate: dayjs(this.props.startDate).format("YYYY-MM-DD")
            })
        }

        if (this.props.endDate && prevProps.endDate !== this.props.endDate) {
            this.setState({
                endDate: dayjs(this.props.endDate).format("YYYY-MM-DD")
            })
        }

        if (prevProps.client !== this.props.client) {
            this.setState({
                client: this.props.client
            })
        }

        if (prevProps.stocks.requesting && !stocks.requesting && formSubmitted) {
            this.setState({
                stockEditModalOpen: false,
		formSubmitted: false,
            }, () => {
                this.getClientReport()
            })
        }
    }

    getClientReport = () => {
        const { startDate, endDate, client, } = this.state
        const { reportClients } = this.props;
        if (client && client.value) {
            reportClients({
                startDate,
                endDate,
                client: (client && client.value) || ""
            })
        }
    }

    onDownload = () => {
        const { startDate, endDate, client, } = this.state
        const { reportClients } = this.props;
        if (client && client.value) {
            reportClients({
                startDate,
                endDate,
                client: (client && client.value) || "",
                type: 'excel'
            })
        }
    }

    onPrint = () => {
        window.print()
    }

    onAction = (action, item) => {
        const { getStock } = this.props
        if (action === 'edit') {
            getStock({
                id: item.id
            })
            this.setState({
                stockEditModalOpen: true
            })
        }
    }

    onSubmit = (values) => {
        const { updateStock, stocks } = this.props
        const formPayload = {
            ...values,
            clientId: values.clientId.value,
            entryType: values.entryType.key,
            itemId: (values.itemId && values.itemId.value) || "",
            stockDate: dayjs(values.stockDate).format("YYYY-MM-DD")
        }
        this.setState({
            formSubmitted: true
        }, () => {
            updateStock({
                ...formPayload,
                id: stocks.record.id
            })
        })

    }

    render() {
        const { session, clientReport } = this.props
        const userAccess = session.user.details.access
        return (
            <>
                <PageHeader
                    title="Client Report"
                    actions={[]}
                    searchComponent={
                        <StockSearchForm
                            forReport={true}
                            onSubmit={this.onTextFormSubmit}
                            userAccess={userAccess}
                            canDownload={clientReport && !clientReport.requesting && (clientReport.data && clientReport.data.stocks && clientReport.data.stocks.length)}
                            onDownload={this.onDownload}
                            onPrint={this.onPrint}
                        />
                    }
                />
                <ClientReport onAction={this.onAction} />
                <Modal
                    title="Edit Stock"
                    hideModal={!this.state.stockEditModalOpen}
                    className='large'
                    type={DialogType.close}
                    toggleHideModal={() => this.setState({
                        stockEditModalOpen: false
                    })}
                >
                    <StockForm edit={true} onSubmit={this.onSubmit} />
                </Modal>
            </>
        )
    }
}

const selector = formValueSelector("stockSearchForm")
const mapStateToProps = ({ session, reports, stocks, ...state }) => ({
    session,
    stocks,
    startDate: selector(state, 'startDate'),
    endDate: selector(state, "endDate"),
    client: selector(state, 'client'),
    clientReport: reports.clientReport
})

const mapDispatchToProps = () => ({
    getClientList,
    getItemList,
    getStock,
    reportClients,
    resetReportClient,
    updateStock
})

export default connect(mapStateToProps, mapDispatchToProps())(ClientReportPage)
