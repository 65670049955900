import React from "react"
import { connect } from "react-redux"
import { Field, reduxForm } from "redux-form"
import { Stack, PrimaryButton } from "@fluentui/react"
import * as FormHepler from "./formHelper"
import * as Validations from "./validation"
import dayjs from "dayjs"


class ItemForm extends React.Component {

    render() {
        const { handleSubmit, edit } = this.props
        return (
            <form onSubmit={handleSubmit} noValidate className="admin-forms">
                <Stack
                    tokens={{ childrenGap: 15 }}
                >
                    <Stack
                        horizontal
                        tokens={{ childrenGap: 15 }}
                    >
                        <Field
                            name="itemName"
                            label="Item Name"
                            required={true}
                            component={FormHepler.RenderTextField}
                            validate={Validations.required}
                        />
                        <Field
                            name="size"
                            label="Size"
                            required={true}
                            component={FormHepler.RenderTextField}
                            validate={Validations.required}
                        />
                        <Field
                            name="grade"
                            label="Grade"
                            required={true}
                            component={FormHepler.RenderTextField}
                            validate={Validations.required}
                        />
                        <Field
                            name="make"
                            label="Make"
                            required={true}
                            component={FormHepler.RenderTextField}
                            validate={Validations.required}
                        />
                    </Stack>
                    <Stack
                        horizontal
                        tokens={{ childrenGap: 15 }}
                    >
                        <Field
                            name="balanceNop"
                            label="Opening Stock (Number of peices)"
                            type="number"
                            component={FormHepler.RenderTextField}
                        // disabled={edit}
                        />
                        <Field
                            name="balanceSize"
                            label="Opening Stock (Weight Kgs)"
                            type="number"
                            component={FormHepler.RenderTextField}
                        // disabled={edit}
                        />
                        <Field
                            name="balanceStockDate"
                            placeholder="Select Opening Stock Date"
                            component={FormHepler.RenderDatePicker}
                            required={true}
                            label="Opening Balance Date"
                            maxDate={new Date()}
                        // disabled={edit}
                        />
                    </Stack>
                    <Stack
                        horizontal
                        tokens={{ childrenGap: 15 }}
                    >
                        <Field
                            name="calculateByNop"
                            label="Rate by Number of Peices"
                            onText="Yes"
                            offText="No"
                            component={FormHepler.RenderToggleControl}
                        />
                    </Stack>
                    <div className="form-actions">
                        <PrimaryButton type="submit">{edit ? "Update" : "Add"} Item</PrimaryButton>
                    </div>
                </Stack>
            </form>
        )
    }
}

const ItemFormMapped = reduxForm({
    form: "itemForm",
    enableReinitialize: true,
})(ItemForm)

const getInitialForm = (record) => {
    let form = {
        itemName: (record && record.itemName) || "",
        size: (record && record.size) || "",
        grade: (record && record.grade) || "",
        balanceSize: (record && record.balanceSize) || 0,
        balanceNop: (record && record.balanceNop) || 0,
        make: (record && record.make) || "",
    }

    if (record) {
        const balanceStockDate = (record && dayjs(record.balanceStockDate, "YYYY-MM-DD").toDate()) || new Date()
        form = {
            ...form,
            calculateByNop: record.calculateByNop ? true : false,
            balanceStockDate
        }
    } else {
        form = {
            balanceSize: 0,
            balanceNop: 0,
            calculateByNop: false
        }
    }

    return form
}

const mapStateToProps = ({ items }) => ({ items, initialValues: getInitialForm(items.record) })

export default connect(mapStateToProps)(ItemFormMapped)