import React from "react"
import { connect } from "react-redux"
import { Field, reduxForm } from "redux-form"
import { Stack, PrimaryButton } from "@fluentui/react"
import * as FormHepler from "./formHelper"
import * as Validations from "./validation"
import dayjs from "dayjs"
class ClientForm extends React.Component {

    render() {
        const { handleSubmit, edit } = this.props
        return (
            <form onSubmit={handleSubmit} noValidate className="admin-forms">
                <Stack
                    tokens={{ childrenGap: 15 }}
                >
                    <Stack
                        horizontal
                        tokens={{ childrenGap: 15 }}
                    >
                        <Field
                            name="clientName"
                            label="Client Name"
                            required={true}
                            component={FormHepler.RenderTextField}
                            validate={Validations.required}
                        />
                        <Field
                            name="openingBalance"
                            label="Opening Balance (INR)"
                            type="number"
                            component={FormHepler.RenderTextField}
                        />
                        <Field
                            name="openingBalanceDate"
                            placeholder="Select Opening Balance Date"
                            component={FormHepler.RenderDatePicker}
                            required={true}
                            label="Opening Balance Date"
                            maxDate={new Date()}
                            disabled={edit}
                        />
                    </Stack>
                    <div className="form-actions">
                        <PrimaryButton type="submit">{edit ? "Update" : "Add"} Client</PrimaryButton>
                    </div>
                </Stack>
            </form>
        )
    }
}

const ClientFormMapped = reduxForm({
    form: "clientForm",
    enableReinitialize: true,
})(ClientForm)

const getInitialForm = (record) => {
    let form = {
        clientName: (record && record.clientName) || "",
        openingBalance: (record && record.openingBalance) || 0,
    }

    if (record) {
        const openingBalanceDate = (record && dayjs(record.openingBalanceDate, "YYYY-MM-DD").toDate()) || new Date()
        form = {
            ...form,
            openingBalanceDate
        }
    }

    return form
}

const mapStateToProps = ({ clients }) => ({ clients, initialValues: getInitialForm(clients.record) })

export default connect(mapStateToProps)(ClientFormMapped)