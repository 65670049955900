import * as actions from "./actionTypes"

export const reportItemsStock = (payload) => ({
    type: actions.REPORT_ITEMS_STOCK_REQUEST,
    payload
})

export const reportItemsStockSuccess = (payload) => ({
    type: actions.REPORT_ITEMS_STOCK_SUCCESS,
    payload
})

export const reportItemsStockError = (payload) => ({
    type: actions.REPORT_ITEMS_STOCK_ERROR,
    payload
})

export const reportClients = (payload) => ({
    type: actions.REPORT_CLIENTS_REQUEST,
    payload
})

export const reportClientsSuccess = (payload) => ({
    type: actions.REPORT_CLIENTS_SUCCESS,
    payload
})

export const reportClientsError = (payload) => ({
    type: actions.REPORT_CLIENTS_ERROR,
    payload
})

export const resetReportClient = (payload) => ({
    type: actions.RESET_REPORT_CLIENT,
    payload
})

export const reportItems = (payload) => ({
    type: actions.REPORT_ITEM_REQUEST,
    payload
})

export const reportItemsSuccess = (payload) => ({
    type: actions.REPORT_ITEM_SUCCESS,
    payload
})

export const reportItemsError = (payload) => ({
    type: actions.REPORT_ITEM_ERROR,
    payload
})

export const reportItemReset = () => ({
    type: actions.REPORT_ITEM_STOCK_RESET
})

export const reportItem = (payload) => ({
    type: actions.REPORT_ITEM_STOCK_REQUEST,
    payload
})

export const reportItemSuccess = (payload) => ({
    type: actions.REPORT_ITEM_STOCK_SUCCESS,
    payload
})

export const reportItemError = (payload) => ({
    type: actions.REPORT_ITEM_STOCK_ERROR,
    payload
})

export const reportStockBalance = (payload) => ({
    type: actions.REPORT_ITEM_STOCK_BALANCE_REQUEST,
    payload
})

export const reportStockBalanceSuccess = (payload) => ({
    type: actions.REPORT_ITEM_STOCK_BALANCE_SUCCESS,
    payload
})

export const reportStockBalanceError = (payload) => ({
    type: actions.REPORT_ITEM_STOCK_BALANCE_ERROR,
    payload
})

export const reportStockBalanceReset = () => ({
    type: actions.REPORT_ITEM_STOCK_BALANCE_RESET
})