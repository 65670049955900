import { IconButton } from "@fluentui/react";
import dayjs from "dayjs";

export const CASH_IN = "CASH_IN";
export const CASH_OUT = "CASH_OUT";

export const ENTRY_TYPES = [
  {
    key: CASH_IN,
    text: "Cash In",
  },
  {
    key: CASH_OUT,
    text: "Cash Out",
  },
];

export const getColumns = (onAction, access) => {
  let columns = [
    {
      key: "client.clientName",
      name: "Client Name",
      fieldName: "client.clientName",
      onRender: (item, index, columns) => {
        return item.client.clientName;
      },
    },
    {
      key: "cashType",
      name: "Type",
      fieldName: "cashType",
      data: "string",
      onRender: (item, index, columns) => {
        const entry = ENTRY_TYPES.find(
          (entryType) => entryType.key === item.cashType
        );
        return entry.text;
      },
    },
    {
      key: "amount",
      name: "Amount",
      fieldName: "amount",
    },
    {
      key: "status",
      name: "Status",
      fieldName: "status",
      onRender: (item, index, columns) => {
        return item.status
          .replace("_", " ")
          .toLowerCase()
          .replace(/\b[a-z]/g, function (letter) {
            return letter.toUpperCase();
          });
      },
    },
    {
      key: "ownedBy.name",
      name: "Owned By",
      fieldName: "ownedBy.name",
      onRender: (item, index, columns) => {
        return item.ownedBy.name;
      },
    },
    {
      key: "cashDate",
      name: "Date Added",
      fieldName: "cashDate",
      onRender: (item, index, columns) => {
        return dayjs(item.cashDate, "YYYY-MM-DD").format("DD MMM, YYYY");
      },
    },
    {
      key: "approvedBy.name",
      name: "Approved By",
      fieldName: "approvedBy.name",
      onRender: (item, index, columns) => {
        return (item.approvedBy && item.approvedBy.name) || "-";
      },
    },
    {
      key: "remark",
      name: "Remarks",
      fieldName: "remark",
    },
  ];

  if (access === "SA") {
    columns.push({
      key: "action",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item, index, column) => {
        return (
          (!!!item.approvedBy && (
            <>
              <IconButton
                iconProps={{ iconName: "TriggerApproval" }}
                onClick={() => onAction("approve", item)}
              />
              <IconButton
                iconProps={{ iconName: "Edit" }}
                onClick={() => onAction("edit", item)}
              />
            </>
          )) ||
          null
        );
      },
    });
  }

  return columns;
};
