import { all, call, takeEvery, put, takeLatest } from "redux-saga/effects"
import ItemService from "../../services/ItemService"
import * as actions from "../actions/actionTypes"
import {
    getItemListSuccess,
    getItemListError,
    addItemSuccess,
    addItemError,
    getItemSuccess,
    getItemError,
    updateItemSuccess,
    updateItemError
} from "../actions/itemActions"


function* list(data) {
    try {
        const ajax = yield call(ItemService.list, data.payload)
        if (ajax.status === 200) {
            yield put(
                getItemListSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                getItemListError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            getItemListError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

function* add(data) {
    try {
        const ajax = yield call(ItemService.add, data.payload)
        if (ajax.status === 201) {
            yield put(
                addItemSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                addItemError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            addItemError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

function* get(data) {
    try {
        const ajax = yield call(ItemService.get, data.payload)
        if (ajax.status === 200) {
            yield put(
                getItemSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                getItemError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            getItemError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

function* update(data) {
    try {
        const ajax = yield call(ItemService.update, data.payload)
        if (ajax.status === 200) {
            yield put(
                updateItemSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                updateItemError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            updateItemError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

export default function* itemSaga() {
    yield all([
        takeLatest(actions.ITEM_LIST_REQUEST, list),
        takeEvery(actions.ITEM_ADD_REQUEST, add),
        takeEvery(actions.ITEM_GET_REQUEST, get),
        takeLatest(actions.ITEM_UPDATE_REQUEST, update),
    ])
}