import * as actions from "./actionTypes"

export const getClientList = (payload) => ({
    type: actions.CLIENT_LIST_REQUEST,
    payload
})

export const getClientListSuccess = (payload) => ({
    type: actions.CLIENT_LIST_SUCCESS,
    payload
})

export const getClientListError = (payload) => ({
    type: actions.CLIENT_LIST_ERROR,
    payload
})

export const addClient = (payload) => ({
    type: actions.CLIENT_ADD_REQUEST,
    payload
})

export const addClientSuccess = (payload) => ({
    type: actions.CLIENT_ADD_SUCCESS,
    payload
})

export const addClientError = (payload) => ({
    type: actions.CLIENT_ADD_ERROR,
    payload
})

export const getClient = (payload) => ({
    type: actions.CLIENT_GET_REQUEST,
    payload
})

export const getClientSuccess = (payload) => ({
    type: actions.CLIENT_GET_SUCCESS,
    payload
})

export const getClientError = (payload) => ({
    type: actions.CLIENT_GET_ERROR,
    payload
})

export const updateClient = (payload) => ({
    type: actions.CLIENT_UPDATE_REQUEST,
    payload
})

export const updateClientSuccess = (payload) => ({
    type: actions.CLIENT_UPDATE_SUCCESS,
    payload
})

export const updateClientError = (payload) => ({
    type: actions.CLIENT_UPDATE_ERROR,
    payload
})