import BaseService from "./BaseService"

class ItemService {

    static async list(payload) {
        return await BaseService.callAPI(`items?page=${payload.page || 1}&query=${payload.query || ""}`, "GET", payload)
    }

    static async add(payload) {
        return await BaseService.callAPI("items", "PUT", payload)
    }

    static async get(payload) {
        return await BaseService.callAPI(`items/${payload.id}`, "GET", payload)
    }

    static async update(payload) {
        return await BaseService.callAPI(`items/${payload.id}`, "POST", payload)
    }
}

export default ItemService