import React from "react"
import { connect } from "react-redux"
import { formValueSelector } from "redux-form"
import { List } from "../../shared/list"
import { PageHeader } from "../../shared/pageHeader"
import { getColumns } from "./constants"
import { getUserList } from "../../../store/actions/userActions"
import TextSearchForm from "../../forms/textSearchForm"
import _ from "lodash"

class UserListPage extends React.Component {

    state = {
        page: 1,
        searchQuery: ""
    }

    componentDidMount() {
        this.getUserList()
    }

    componentDidUpdate(prevProps, prevState) {
        const { page, searchQuery } = this.state
        const { query } = this.props
        if (prevState.page !== page || prevState.searchQuery !== searchQuery) {
            this.getUserList()
        }

        if (prevProps.query !== query) {
            this.setState({
                searchQuery: _.trim(query)
            })
        }
    }

    getUserList = () => {
        const { page, searchQuery } = this.state
        const { getUserList } = this.props
        getUserList({
            page,
            query: searchQuery
        })
    }

    onPageHeaderActionClick = (action, addtionalParams) => {
        const { history } = this.props
        if (action === "add") {
            history.push("/users/add")
        } else if (action === "edit") {
            history.push(`/users/edit/${addtionalParams.id}`)
        }
    }

    onPageChange = (page) => {
        this.setState({
            page: page + 1
        }, () => {
            this.getClients()
        })
    }

    onTextFormSubmit = (values) => {
        this.setState({
            searchQuery: _.trim(values.query)
        })
    }

    render() {
        const { users } = this.props
        return (
            <>
                <PageHeader
                    title="Users"
                    actions={['add']}
                    onAction={this.onPageHeaderActionClick}
                    searchComponent={<TextSearchForm onSubmit={this.onTextFormSubmit} />}
                />
                <List
                    pagination={(users.list && users.list.pagination) || {}}
                    columns={getColumns(this.onPageHeaderActionClick)}
                    data={(users.list && users.list.data) || []}
                    isLoading={users.requesting}
                    onPageChange={this.onPageChange}
                    sortCol="name"
                />
            </>
        )
    }
}
const selector = formValueSelector("textSearchForm")
const mapStateToProps = ({ users, ...state }) => ({ users, query: selector(state, 'query') })

const mapDispatchToProps = () => ({
    getUserList
})

export default connect(mapStateToProps, mapDispatchToProps())(UserListPage)