import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import { Stack, PrimaryButton } from "@fluentui/react";
import dayjs from "dayjs";
import * as FormHepler from "./formHelper";
import _ from "lodash";

class ItemReportForm extends React.Component {
  componentDidMount() {
    const { change } = this.props;
    change("startDate", dayjs().subtract(15, "day").toDate());
  }

  onClear = () => {
    const { change } = this.props;
    change("client", null);
    change("entryType", {});
  };

  onDownload = () => {
    const { onDownload } = this.props;
    if (onDownload) {
      onDownload();
    }
  };

  onPrint = () => {
    const { onPrint } = this.props;
    if (onPrint) {
      onPrint();
    }
  };

  render() {
    const {
      handleSubmit,
      startDate,
      items,
      canDownload,
      userAccess,
      onPrint,
      itemType,
    } = this.props;
    const groupedItems = _.groupBy(items || [], (item) =>
      item.itemName.toLowerCase()
    );
    const itemTypes = Object.keys(groupedItems).map((item) => {
      return {
        value: item,
        label: item.toLowerCase().replace(/\b[a-z]/g, function (letter) {
          return letter.toUpperCase();
        }),
      };
    });

    return (
      <form onSubmit={handleSubmit} noValidate className="no-print">
        <Stack horizontal={true} tokens={{ childrenGap: 15 }}>
          <Field
            name="startDate"
            placeholder="Select Start Date"
            component={FormHepler.RenderDatePicker}
            maxDate={new Date()}
          />
          <Field
            name="endDate"
            placeholder="Select End Date"
            component={FormHepler.RenderDatePicker}
            maxDate={new Date()}
            minDate={startDate || new Date()}
          />
          <Field
            iconProps={{ iconName: "Search" }}
            name="itemType"
            placeholder="Select By Item Type"
            width={200}
            component={FormHepler.RenderSearchDropDown}
            options={itemTypes}
          />
          <Field
            iconProps={{ iconName: "Search" }}
            name="item"
            placeholder="Select Item"
            width={200}
            component={FormHepler.RenderSearchDropDown}
            options={(items || [])
              .filter((item) => item.itemName.toLowerCase() === itemType?.value)
              .map((item) => {
                return {
                  value: item.id,
                  label: `${item.itemName}/${item.size}/${item.grade}/${
                    item.make || ""
                  }`,
                };
              })}
          />
          <PrimaryButton type="button" onClick={this.onClear}>
            Clear Search
          </PrimaryButton>
          {(userAccess === "SA" && (
            <PrimaryButton
              disabled={!canDownload}
              iconProps={{ iconName: "ExcelDocument" }}
              type="button"
              onClick={this.onDownload}
            >
              Download
            </PrimaryButton>
          )) ||
            null}
          {(userAccess === "SA" && onPrint && (
            <PrimaryButton
              disabled={!canDownload}
              iconProps={{ iconName: "Print" }}
              type="button"
              onClick={this.onPrint}
            >
              Print
            </PrimaryButton>
          )) ||
            null}
        </Stack>
      </form>
    );
  }
}

const ItemReportFormMapped = reduxForm({
  form: "itemReportForm",
  enableReinitialize: true,
})(ItemReportForm);

const mapDispatchToProps = () => ({
  change,
});

const getInitialForm = (state) => {
  const groupedItems = _.groupBy(state.meta.items || [], (item) =>
    item.itemName.toLowerCase()
  );
  const itemTypes = Object.keys(groupedItems).map((item) => {
    return {
      value: item,
      label: item.toLowerCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
      }),
    };
  });
  return {
    startDate: "",
    endDate: "",
    itemType: (itemTypes?.length && itemTypes[0]) || null,
  };
};

const selector = formValueSelector("itemReportForm");
const mapStateToProps = (state) => ({
  initialValues: getInitialForm(state),
  startDate: selector(state, "startDate"),
  itemType: selector(state, "itemType"),
  items: state.meta.items,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(ItemReportFormMapped);
