import { all, call, put, takeLatest } from "redux-saga/effects"
import MetaService from "../../services/MetaService"
import * as actions from "../actions/actionTypes"
import {
    getClientListError,
    getClientListSuccess,
    getItemListSuccess,
    getItemListError
} from "../actions/metaActions"

function* clients(data) {
    try {
        const ajax = yield call(MetaService.clients, data.payload)
        if (ajax.status === 200) {
            yield put(
                getClientListSuccess({
                    status: ajax.status,
                    list: ajax.data.response,
                })
            )
        } else {
            yield put(
                getClientListError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            getClientListError({
                status: (error.response && error.response.status) || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

function* items(data) {
    try {
        const ajax = yield call(MetaService.items, data.payload)
        if (ajax.status === 200) {
            yield put(
                getItemListSuccess({
                    status: ajax.status,
                    list: ajax.data.response,
                })
            )
        } else {
            yield put(
                getItemListError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            getItemListError({
                status: (error.response && error.response.status) || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

export default function* metaSaga() {
    yield all([
        takeLatest(actions.META_CLIENT_LIST_REQUEST, clients),
        takeLatest(actions.META_ITEM_LIST_REQUEST, items)
    ])
}