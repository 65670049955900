import * as actions from "../actions/actionTypes"
import { clients } from "./initialState"

const clientReducer = (state = clients, action) => {
    switch (action.type) {
        case actions.CLIENT_LIST_REQUEST:
        case actions.CLIENT_ADD_REQUEST:
        case actions.CLIENT_GET_REQUEST:
            return {
                ...state,
                requesting: true,
                error: false,
                errorBody: null,
                record: null,
            }
        case actions.CLIENT_UPDATE_REQUEST:
            return {
                ...state,
                requesting: true,
                error: false,
                errorBody: null,
            }
        case actions.CLIENT_LIST_SUCCESS:
            return {
                ...state,
                requesting: false,
                list: action.payload,
            }
        case actions.CLIENT_ADD_SUCCESS:
        case actions.CLIENT_UPDATE_SUCCESS:
            return {
                ...state,
                requesting: false,
            }
        case actions.CLIENT_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                record: action.payload,
            }
        case actions.CLIENT_ADD_ERROR:
        case actions.CLIENT_GET_ERROR:
        case actions.CLIENT_LIST_ERROR:
        case actions.CLIENT_UPDATE_ERROR:
            return {
                ...state,
                requesting: false,
                error: true,
                errorBody: action.payload,
            }
        default:
            return state
    }
}

export default clientReducer;