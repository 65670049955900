import axios from "axios"
import { API_URL } from "../utils/settings"

const client = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})

class BaseService {

  static async excuteAPI(
    url,
    method = "get",
    data = null,
    addtionalOptions = {},
    additionalHdrs = {}
  ) {
    try {
      const payloadData = data

      const headers = {
        "Content-Type":
          additionalHdrs.contentType || "application/json; charset=utf-8",
        Accept: "application/json",
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
      }

      if (payloadData.accessToken) {
        headers.Authorization = "Bearer " + payloadData.accessToken
        delete payloadData.accessToken
      }

      const payload = {
        ...addtionalOptions,
      }
      payload.headers = headers
      payload.method = method
      payload.url = url
      if (payloadData !== null && method !== "get") {
        payload.data = payloadData.formData || payloadData
      }

      const response = await client(payload)
      return response
    } catch (error) {
      throw error
    }
  }

  static async callAPI(
    url,
    method = "get",
    data = null,
    addtionalOptions = {},
    additionalHdrs = {}
  ) {
    try {

      if (method !== "get" && !data.accessToken) {
        return client.get(`csrf-cookie`).then(response => {
          return this.excuteAPI(url, method, data, addtionalOptions, additionalHdrs)
        })
      } else {
        return this.excuteAPI(url, method, data, addtionalOptions, additionalHdrs)
      }

    } catch (error) {
      return error
    }
  }
}

export default BaseService
