const STORAGE_TYPE =
  typeof window.localStorage !== "undefined" ? window.localStorage : null

export const STORAGE_KEY = "stocks"

export const getItem = (key) => {
  return STORAGE_TYPE == null ? null : STORAGE_TYPE.getItem(key)
}

export const setItem = (key, value) => {
  if (STORAGE_TYPE !== null) {
    STORAGE_TYPE.setItem(key, value)
  }
}
