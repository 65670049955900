import React, { useRef, useState } from "react"
import { connect } from "react-redux"
import { Layer, Persona, FontWeights, FontSizes, PersonaSize, ContextualMenu } from "@fluentui/react"
import { logout } from "../../../store/actions/userActions"
import "./navBar.scss"

const NavBar = (props) => {

    const [showUserOptions, setShowUserOptions] = useState(false)
    const linkRef = useRef(null)

    const getInitials = (string) => {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }


    const { session: { user } } = props

    const options = [
        { key: 'logout', text: 'Log Out', onClick: () => props.logout() },
    ]

    return (
        <Layer className="no-print">
            <div className="top-nav-bar container">
                <div className="app-title" style={{ fontWeight: FontWeights.semibold, fontSize: FontSizes.size16 }}>
                    Stocks Manager
                    </div>
                {user && (<div className="right-actions">
                    <div className="user-actions">
                        <Persona
                            ref={linkRef}
                            text={user.details.name}
                            imageInitials={getInitials(user.details.name)}
                            size={PersonaSize.size24}
                            onClick={() => setShowUserOptions(!showUserOptions)}
                        />
                        <ContextualMenu
                            doNotLayer={true}
                            className="user-options"
                            items={options}
                            hidden={!showUserOptions}
                            target={linkRef}
                            onItemClick={() => setShowUserOptions(false)}
                            onDismiss={() => setShowUserOptions(false)}
                        />
                    </div>
                </div>)}
            </div>
        </Layer>
    )
}

const mapStateToProps = ({ session }) => ({
    session
})

const mapDispatchToProps = () => ({
    logout
})

export default connect(mapStateToProps, mapDispatchToProps())(NavBar)