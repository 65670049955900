export const fromCamelCaseToSpaceCase = (str) => {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, function (item) {
    return item.toUpperCase();
  });
};

export const downloadFile = (ajax) => {
  const headerval = ajax.headers["content-disposition"];
  var filename = headerval
    .split(";")[1]
    .split("=")[1]
    .replace('"', "")
    .replace('"', "");
  const url = window.URL.createObjectURL(new Blob([ajax.data]), {
    type: ajax.headers["content-type"],
  });
  const link = document.createElement("a");
  link.href = url;

  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
