import * as actions from "../actions/actionTypes"
import { cashbooks } from "./initialState"

const cashbookReducer = (state = cashbooks, action) => {
    switch (action.type) {
        case actions.CASHBOOK_LIST_REQUEST:
        case actions.CASHBOOK_ADD_REQUEST:
        case actions.CASHBOOK_GET_REQUEST:
        case actions.CASHBOOK_UPDATE_APPROVE_REQUEST:
            return {
                ...state,
                requesting: true,
                error: false,
                errorBody: null,
                record: null,
            }
        case actions.CASHBOOK_UPDATE_REQUEST:
            return {
                ...state,
                requesting: true,
                error: false,
                errorBody: null,
            }
        case actions.CASHBOOK_LIST_SUCCESS:
            return {
                ...state,
                requesting: false,
                list: action.payload,
            }
        case actions.CASHBOOK_ADD_SUCCESS:
        case actions.CASHBOOK_UPDATE_SUCCESS:
        case actions.CASHBOOK_UPDATE_APPROVE_SUCCESS:
            return {
                ...state,
                requesting: false,
            }
        case actions.CASHBOOK_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                record: action.payload,
            }
        case actions.CASHBOOK_ADD_ERROR:
        case actions.CASHBOOK_GET_ERROR:
        case actions.CASHBOOK_LIST_ERROR:
        case actions.CASHBOOK_UPDATE_ERROR:
        case actions.CASHBOOK_UPDATE_APPROVE_ERROR:
            return {
                ...state,
                requesting: false,
                error: true,
                errorBody: action.payload,
            }
        default:
            return state
    }
}

export default cashbookReducer;