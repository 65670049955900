import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import dayjs from "dayjs";
import ItemReportForm from "../../forms/itemReportForm";
import { PageHeader } from "../../shared/pageHeader";
import { ItemReport } from "./";
import { getItemList } from "../../../store/actions/metaActions";
import {
  reportItem,
  reportItemReset,
} from "../../../store/actions/reportActions";

class ItemReportPage extends React.Component {
  state = {
    item: "",
    startDate: dayjs().subtract(15, "day").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  };

  getItemReport = () => {
    const { startDate, endDate, item } = this.state;
    const { reportItem } = this.props;
    if (item?.value) {
      reportItem({
        startDate,
        endDate,
        item: (item && item.value) || "",
      });
    }
  };

  onDownload = () => {
    const { startDate, endDate, item } = this.state;
    const { reportItem } = this.props;
    if (item?.value) {
      reportItem({
        startDate,
        endDate,
        item: (item && item.value) || "",
        type: "excel",
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate, item } = this.state;

    if (
      prevState.startDate !== startDate ||
      prevState.endDate !== endDate ||
      prevState.item !== item
    ) {
      this.getItemReport();
    }

    if (this.props.startDate && prevProps.startDate !== this.props.startDate) {
      this.setState({
        startDate: dayjs(this.props.startDate).format("YYYY-MM-DD"),
      });
    }

    if (this.props.endDate && prevProps.endDate !== this.props.endDate) {
      this.setState({
        endDate: dayjs(this.props.endDate).format("YYYY-MM-DD"),
      });
    }

    if (prevProps.item !== this.props.item) {
      this.setState({
        item: this.props.item,
      });
    }
  }

  componentDidMount() {
    const { getItemList, reportItemReset } = this.props;
    getItemList();
    reportItemReset();
  }

  onPrint = () => {
    window.print();
  };

  render() {
    const { session, reportItems } = this.props;
    const { startDate, endDate, item } = this.state;
    const userAccess = session.user.details.access;
    return (
      <>
        <PageHeader
          title="Item Report"
          actions={[]}
          searchComponent={
            <ItemReportForm
              forReport={true}
              onSubmit={null}
              userAccess={userAccess}
              canDownload={reportItems?.list?.data?.length}
              onPrint={this.onPrint}
              onDownload={this.onDownload}
            />
          }
        />
        {(reportItems?.list?.data?.length && (
          <ItemReport
            item={item?.label || ""}
            startDate={startDate}
            endDate={endDate}
          />
        )) ||
          null}
      </>
    );
  }
}

const selector = formValueSelector("itemReportForm");
const mapStateToProps = ({ session, reports, ...state }) => ({
  session,
  startDate: selector(state, "startDate"),
  endDate: selector(state, "endDate"),
  item: selector(state, "item"),
  reportItems: reports.item,
});

const mapDispatchToProps = () => ({
  getItemList,
  reportItem,
  reportItemReset,
});

export default connect(mapStateToProps, mapDispatchToProps())(ItemReportPage);
