import React from "react"
import { connect } from "react-redux"
import { List } from "../../shared/list"
import { PageHeader } from "../../shared/pageHeader"
import { getColumns } from "./constants"
import { getCashbookList, approveCashBook } from "../../../store/actions/cashbookActions"

class CashbookListPage extends React.Component {

    state = {
        page: 1,
        approveCashBook: false,
    }

    componentDidMount() {
        this.getCashbooks()
    }

    componentDidUpdate(prevProps, prevState) {
        const { page, approveCashBook } = this.state
        const { cashbooks } = this.props
        if (prevState.page !== page) {
            this.getCashbooks()
        }

        if (prevProps.cashbooks.requesting === true &&
            cashbooks.requesting === false &&
            cashbooks.error === false &&
            approveCashBook === true) {
            this.setState({
                approveCashBook: false,
            })
            this.getCashbooks()
        }
    }

    getCashbooks = () => {
        const { page } = this.state
        const { getCashbookList } = this.props
        getCashbookList({
            page,
        })
    }

    onPageHeaderActionClick = (action, addtionalParams) => {
        const { history, approveCashBook } = this.props
        if (action === "add") {
            history.push("/cashbooks/add")
        } else if (action === "edit") {
            history.push(`/cashbooks/edit/${addtionalParams.id}`)
        } else if (action === "approve") {
            this.setState({
                approveCashBook: true
            })
            approveCashBook({
                id: addtionalParams.id
            })
        }
    }

    onPageChange = (page) => {
        this.setState({
            page: page + 1
        }, () => {
            this.getCashbooks()
        })
    }

    render() {
        const { cashbooks, session } = this.props
        const userAccess = session.user.details.access
        return (
            <>
                <PageHeader
                    title="Cashbooks"
                    actions={userAccess === "SA" ? [] : ['add']}
                    onAction={this.onPageHeaderActionClick}
                />
                <List
                    pagination={(cashbooks.list && cashbooks.list.pagination) || {}}
                    columns={getColumns(this.onPageHeaderActionClick, userAccess)}
                    data={(cashbooks.list && cashbooks.list.data) || []}
                    isLoading={cashbooks.requesting}
                    onPageChange={this.onPageChange}
                    sortCol="client.clientName"
                />
            </>
        )
    }
}

const mapStateToProps = ({ cashbooks, session, ...state }) => ({ cashbooks, session })

const mapDispatchToProps = () => ({
    getCashbookList,
    approveCashBook
})

export default connect(mapStateToProps, mapDispatchToProps())(CashbookListPage)