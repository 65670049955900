import { all, call, takeEvery, put, takeLatest } from "redux-saga/effects"
import ClientService from "../../services/ClientService"
import * as actions from "../actions/actionTypes"
import {
    getClientListSuccess,
    getClientListError,
    addClientSuccess,
    addClientError,
    getClientSuccess,
    getClientError,
    updateClientSuccess,
    updateClientError
} from "../actions/clientActions"


function* list(data) {
    try {
        const ajax = yield call(ClientService.list, data.payload)
        if (ajax.status === 200) {
            yield put(
                getClientListSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                getClientListError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            getClientListError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

function* add(data) {
    try {
        const ajax = yield call(ClientService.add, data.payload)
        if (ajax.status === 201) {
            yield put(
                addClientSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                addClientError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            addClientError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

function* get(data) {
    try {
        const ajax = yield call(ClientService.get, data.payload)
        if (ajax.status === 200) {
            yield put(
                getClientSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                getClientError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            getClientError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

function* update(data) {
    try {
        const ajax = yield call(ClientService.update, data.payload)
        if (ajax.status === 200) {
            yield put(
                updateClientSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                updateClientError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            updateClientError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

export default function* clientSaga() {
    yield all([
        takeLatest(actions.CLIENT_LIST_REQUEST, list),
        takeEvery(actions.CLIENT_ADD_REQUEST, add),
        takeEvery(actions.CLIENT_GET_REQUEST, get),
        takeLatest(actions.CLIENT_UPDATE_REQUEST, update),
    ])
}