import React from "react"
import { connect } from "react-redux"
import ItemForm from "../../forms/itemForm"
import { PageHeader } from "../../shared/pageHeader"
import { addItem, getItem, updateItem } from "../../../store/actions/itemActions"
import dayjs from "dayjs"

class ItemAddEditPage extends React.Component {

    state = {
        edit: false,
        id: 0,
        formSubmitted: false
    }

    onSubmit = (values) => {
        const { addItem, updateItem, items } = this.props
        const { edit } = this.state
        this.setState({
            formSubmitted: true
        })
        if (!edit) {
            addItem({
                ...values,
                balanceStockDate: dayjs(values.balanceStockDate).format("YYYY-MM-DD")
            })
        } else {
            updateItem({
                ...values,
                balanceStockDate: dayjs(values.balanceStockDate).format("YYYY-MM-DD"),
                id: items.record.id
            })
        }
    }

    componentDidMount() {
        const { match, getItem } = this.props
        if (match.params && match.params.id) {
            this.setState({
                id: parseInt(match.params.id),
                edit: true
            }, () => {
                getItem({
                    id: match.params.id
                })
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { items, history } = this.props
        const { formSubmitted } = this.state
        if (prevProps.items.requesting && !items.requesting && !items.error && formSubmitted) {
            history.push("/items")
        }
    }

    render() {
        const { edit } = this.state
        return (
            <>
                <PageHeader title={`${edit ? "Edit" : "Add"} Items`} />
                <ItemForm onSubmit={this.onSubmit} edit={edit} />
            </>
        )
    }
}

const mapStateToProps = ({ items }) => ({ items })

const mapDispatchToProps = () => ({
    addItem,
    getItem,
    updateItem
})

export default connect(mapStateToProps, mapDispatchToProps())(ItemAddEditPage)