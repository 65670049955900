import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { formValueSelector } from "redux-form"
import { reportItemsStock } from "../../../store/actions/reportActions"
import { ItemStockTile } from "./shared"
import _ from "lodash"
import "./scss/tile.scss"

class ItemsStockList extends React.Component {

    componentDidMount() {
        const { reportItemsStock } = this.props
        reportItemsStock()
    }

    gotoItemHistoryReport(itemId) {
        const { history } = this.props
        history.push(`/items/history/${itemId}`)
    }

    render() {
        const { reports, item } = this.props
        const itemsStock = reports.itemsStock.list || []
        const groupedItemsStock = _.groupBy(itemsStock, (item) => item?.itemName?.toLowerCase())
        const itemTypes = Object.keys(groupedItemsStock).sort().filter(key => item.key === key || item.key === "all")
        return (
            <div className="tile-list">
                {(itemTypes.length > 0 && itemTypes.map(key => {
                    return (
                        <div key={key}>
                            <div className="tile-heading">
                                {key.toLowerCase().replace(/\b[a-z]/g, function (letter) {
                                    return letter.toUpperCase();
                                })}
                            </div>
                            <div className="tiles-list">
                                {groupedItemsStock[key].map(item => {
                                    return <ItemStockTile onClick={() => this.gotoItemHistoryReport(item.id)} key={item.id} title={`${item.itemName}/${item.size}/${item.grade}/${item.make || ""}`} count={`${(item.calculateByNop ? parseInt(item.balanceNop) : parseFloat(item.balanceSize)) + parseInt(item.purchasedStock)}${item.calculateByNop ? "" : " Kgs"}`} />
                                })}
                            </div>
                        </div>
                    )
                })) || (reports.itemsStock.requesting === false &&
                    <div>
                        <span className="error bold">Please <Link to="/items">click here</Link> to add an Item to load Items Stock Statistics.</span>
                    </div>
                    ) || null}
            </div>
        )
    }
}

const selector = formValueSelector("itemSearchForm")
const mapStateToProps = ({ reports, ...state }) => ({ reports, item: selector(state, "item") })

const mapDispatchToProps = () => ({ reportItemsStock })

export default connect(mapStateToProps, mapDispatchToProps())(ItemsStockList)