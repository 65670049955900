import React from "react"
import { connect } from "react-redux"
import { Field, reduxForm, change } from "redux-form"
import { Stack } from "@fluentui/react"
import * as FormHepler from "./formHelper"

class TextSearchForm extends React.Component {

    componentDidMount() {
        const { change } = this.props
        change("query", "")
    }

    render() {
        const { handleSubmit } = this.props
        return (
            <form onSubmit={handleSubmit} noValidate>
                <Stack
                    horizontal={true}
                    tokens={{ childrenGap: 15 }}
                >
                    <Field
                        iconProps={{ iconName: 'Search' }}
                        name="query"
                        placeholder="Search"
                        component={FormHepler.RenderTextField}
                    />
                </Stack>
            </form>
        )
    }
}

const TextSearchFormMapped = reduxForm({
    form: "textSearchForm",
    enableReinitialize: true,
})(TextSearchForm)

const mapDispatchToProps = () => ({
    change
})

export default connect(null, mapDispatchToProps)(TextSearchFormMapped)