import * as actions from "../actions/actionTypes"
import { session } from "./initialState"

const sessionReducer = (state = session, action) => {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
    case actions.ME_REQUEST:
    case actions.YOUR_UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        requesting: true,
        error: false,
        errorBody: null,
      }
    case actions.YOUR_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        requesting: false,
      }
    case actions.YOUR_UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        requesting: false,
        error: true,
        errorBody: action.payload,
      }
    case actions.LOGIN_ERROR:
    case actions.ME_ERROR:
      return {
        ...state,
        user: null,
        requesting: false,
        error: true,
        errorBody: action.payload,
      }
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        requesting: false,
        error: false,
        errorBody: null,
      }
    case actions.ME_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          details: action.payload.response
        },
        requesting: false,
        error: false,
        errorBody: null,
      }
    case actions.LOGOUT_USER:
      return {
        ...state,
        user: null,
        requesting: false,
        error: false,
        errorBody: null,
      }
    default:
      return state
  }
}

export default sessionReducer;
