import * as actions from "../actions/actionTypes";
import { reports } from "./initialState";

const reportReducer = (state = reports, action) => {
  switch (action.type) {
    case actions.REPORT_ITEMS_STOCK_REQUEST:
      return {
        ...state,
        itemsStock: {
          ...state.itemsStock,
          requesting: true,
        },
      };
    case actions.REPORT_ITEMS_STOCK_SUCCESS:
      return {
        ...state,
        itemsStock: {
          ...state.itemsStock,
          requesting: false,
          list: action.payload.data,
        },
      };
    case actions.REPORT_ITEMS_STOCK_ERROR:
      return {
        ...state,
        itemsStock: {
          ...state.itemsStock,
          requesting: false,
        },
      };
    case actions.REPORT_CLIENTS_REQUEST:
      return {
        ...state,
        clientReport: {
          ...state.clientReport,
          requesting: true,
          data:
            action.payload.type !== "excel" ? null : state.clientReport.data,
        },
      };
    case actions.REPORT_CLIENTS_SUCCESS:
      return {
        ...state,
        clientReport: {
          ...state.clientReport,
          requesting: false,
          data:
            (action.payload.type !== "excel" && action.payload) ||
            state.clientReport.data,
        },
      };
    case actions.REPORT_CLIENTS_ERROR:
    case actions.RESET_REPORT_CLIENT:
      return {
        ...state,
        clientReport: {
          ...state.clientReport,
          requesting: false,
          data: null,
        },
      };
    case actions.REPORT_ITEM_REQUEST:
      return {
        ...state,
        itemHistory: {
          ...state.clientReport,
          requesting: true,
          data: null,
        },
      };
    case actions.REPORT_ITEM_SUCCESS:
      return {
        ...state,
        itemHistory: {
          ...state.clientReport,
          requesting: false,
          data: action.payload,
        },
      };
    case actions.REPORT_ITEM_ERROR:
      return {
        ...state,
        itemHistory: {
          ...state.clientReport,
          requesting: false,
          data: null,
        },
      };
    case actions.REPORT_ITEM_STOCK_REQUEST:
      return {
        ...state,
        item: {
          ...state.item,
          list: action.payload.type !== "excel" ? null : state.item.list,
          requesting: true,
        },
      };
    case actions.REPORT_ITEM_STOCK_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          list:
            action.payload.type !== "excel" ? action.payload : state.item.list,
          requesting: false,
        },
      };
    case actions.REPORT_ITEM_STOCK_ERROR:
      return {
        ...state,
        item: {
          ...state.item,
          list: null,
          requesting: false,
        },
      };
    case actions.REPORT_ITEM_STOCK_RESET:
      return {
        ...state,
        item: {
          ...state.item,
          list: [],
          requesting: false,
        },
      };
    case actions.REPORT_ITEM_STOCK_BALANCE_REQUEST:
      return {
        ...state,
        itemStockBalance: {
          ...state.itemStockBalance,
          list: [],
          requesting: true,
        },
      };
    case actions.REPORT_ITEM_STOCK_BALANCE_SUCCESS:
      return {
        ...state,
        itemStockBalance: {
          ...state.itemStockBalance,
          list: action.payload,
          requesting: false,
        },
      };
    case actions.REPORT_ITEM_STOCK_BALANCE_ERROR:
      return {
        ...state,
        itemStockBalance: {
          ...state.itemStockBalance,
          list: null,
          requesting: false,
        },
      };
    case actions.REPORT_ITEM_STOCK_BALANCE_RESET:
      return {
        ...state,
        itemStockBalance: {
          ...state.itemStockBalance,
          list: [],
          requesting: false,
        },
      };
    default:
      return state;
  }
};

export default reportReducer;
