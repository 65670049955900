import { all, call, takeEvery, put, takeLatest } from "redux-saga/effects"
import UserService from "../../services/UserService"
import * as actions from "../actions/actionTypes"
import {
  loginSuccess,
  loginFail,
  meSuccess,
  meFail,
  getUserListSuccess,
  getUserListError,
  addUserSuccess,
  addUserError,
  getUserSuccess,
  getUserError,
  updateUserSuccess,
  updateUserError,
  updateUserPasswordSuccess,
  updateUserPasswordError
} from "../actions/userActions"

function* login(data) {
  try {
    const ajax = yield call(UserService.login, data.payload)
    if (ajax.status === 200) {
      yield put(
        loginSuccess({
          ...ajax.data.response,
        })
      )
    } else {
      yield put(
        loginFail({
          status: ajax.status,
          errors: ajax.data.response,
        })
      )
    }
  } catch (error) {
    yield put(
      loginFail({
        status: (error && error.response && error.response.status) || 400,
        errors: (error && error.response && error.response.data && error.response.data.response) || { error: "Invalid login details. Please try again." },
        message: (error && error.message) || "Error. Please try again later",
      })
    )
  }
}

function* me(data) {
  try {
    const ajax = yield call(UserService.me, data.payload)
    if (ajax.status === 200) {
      yield put(
        meSuccess({
          ...ajax.data,
        })
      )
    } else {
      yield put(
        meFail({
          status: ajax.status,
          ...ajax.data,
        })
      )
    }
  } catch (error) {
    yield put(
      meFail({
        status: error.response.status || 500,
        errors: (error.response && error.response.data && error.response.data.response) || {},
        error: (error && error.message) || "Error. Please try again later",
      })
    )
  }
}

function* list(data) {
  try {
    const ajax = yield call(UserService.list, data.payload)
    if (ajax.status === 200) {
      yield put(
        getUserListSuccess({
          status: ajax.status,
          ...ajax.data.response,
        })
      )
    } else {
      yield put(
        getUserListError({
          status: ajax.status,
          errors: ajax.data.response,
        })
      )
    }
  } catch (error) {
    yield put(
      getUserListError({
        status: error.response.status || 500,
        errors: (error.response && error.response.data && error.response.data.response) || {},
        message: (error && error.message) || "Error. Please try again later",
      })
    )
  }
}

function* add(data) {
  try {
    const ajax = yield call(UserService.add, data.payload)
    if (ajax.status === 201) {
      yield put(
        addUserSuccess({
          status: ajax.status,
          ...ajax.data.response,
        })
      )
    } else {
      yield put(
        addUserError({
          status: ajax.status,
          errors: ajax.data.response,
        })
      )
    }
  } catch (error) {
    yield put(
      addUserError({
        status: error.response.status || 500,
        errors: (error.response && error.response.data && error.response.data.response) || {},
        message: (error && error.message) || "Error. Please try again later",
      })
    )
  }
}

function* get(data) {
  try {
    const ajax = yield call(UserService.get, data.payload)
    if (ajax.status === 200) {
      yield put(
        getUserSuccess({
          status: ajax.status,
          ...ajax.data.response,
        })
      )
    } else {
      yield put(
        getUserError({
          status: ajax.status,
          errors: ajax.data.response,
        })
      )
    }
  } catch (error) {
    yield put(
      getUserError({
        status: error.response.status || 500,
        errors: (error.response && error.response.data && error.response.data.response) || {},
        message: (error && error.message) || "Error. Please try again later",
      })
    )
  }
}

function* update(data) {
  try {
    const ajax = yield call(UserService.update, data.payload)
    if (ajax.status === 200) {
      yield put(
        updateUserSuccess({
          status: ajax.status,
          ...ajax.data.response,
        })
      )
    } else {
      yield put(
        updateUserError({
          status: ajax.status,
          errors: ajax.data.response,
        })
      )
    }
  } catch (error) {
    yield put(
      updateUserError({
        status: error.response.status || 500,
        errors: (error.response && error.response.data && error.response.data.response) || {},
        message: (error && error.message) || "Error. Please try again later",
      })
    )
  }
}

function* updatePassword(data) {
  try {
    const ajax = yield call(UserService.updatePassword, data.payload)
    if (ajax.status === 200) {
      yield put(
        updateUserPasswordSuccess({
          status: ajax.status,
          ...ajax.data.response,
        })
      )
    } else {
      yield put(
        updateUserPasswordError({
          status: ajax.status,
          errors: ajax.data.response,
        })
      )
    }
  } catch (error) {
    yield put(
      updateUserPasswordError({
        status: error.response.status || 500,
        errors: (error.response && error.response.data && error.response.data.response) || {},
        message: (error && error.message) || "Error. Please try again later",
      })
    )
  }
}


export default function* userSaga() {
  yield all([
    takeEvery(actions.LOGIN_REQUEST, login),
    takeEvery(actions.ME_REQUEST, me),
    takeLatest(actions.USER_LIST_REQUEST, list),
    takeEvery(actions.USER_ADD_REQUEST, add),
    takeEvery(actions.USER_GET_REQUEST, get),
    takeEvery(actions.USER_UPDATE_REQUEST, update),
    takeLatest(actions.YOUR_UPDATE_PASSWORD_REQUEST, updatePassword),
  ])
}
