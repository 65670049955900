import { all } from "redux-saga/effects"
import userSaga from "./UserSaga"
import clientSaga from "./ClientSaga"
import stockSaga from "./StockSaga"
import metaSaga from "./MetaSaga"
import cashbookSaga from "./CashBookSaga"
import itemSaga from "./ItemSaga"
import reportSaga from "./ReportSaga"

export default function* rootSaga() {
  yield all([
    userSaga(),
    clientSaga(),
    stockSaga(),
    metaSaga(),
    cashbookSaga(),
    itemSaga(),
    reportSaga(),
  ])
}
