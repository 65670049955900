import { combineReducers } from "redux"
import session from "./sessionReducer"
import loader from "./loaderReducer"
import global from "./globalReducer"
import clients from "./clientReducer"
import items from "./itemReducer"
import stocks from "./stockReducer"
import meta from "./metaReducer"
import users from "./userReducer"
import cashbooks from "./cashbookReducer"
import reports from "./reportReducer"
import { reducer as formReducer } from "redux-form"

const createReducer = () => {
  return combineReducers({
    session,
    loader,
    global,
    meta,
    clients,
    items,
    stocks,
    cashbooks,
    users,
    reports,
    form: formReducer,
  })
}

export default createReducer
