import BaseService from "./BaseService"

class UserService {

  static async login(payload) {
    return await BaseService.callAPI("login", "POST", payload)
  }

  static async me(payload) {
    return await BaseService.callAPI("me", "GET", payload)
  }

  static async updatePassword(payload) {
    return await BaseService.callAPI("me/update-password", "POST", payload)
  }

  static async list(payload) {
    return await BaseService.callAPI(`users?page=${payload.page || 1}&query=${payload.query || ""}`, "GET", payload)
  }

  static async add(payload) {
    return await BaseService.callAPI("users", "PUT", payload)
  }

  static async get(payload) {
    return await BaseService.callAPI(`users/${payload.id}`, "GET", payload)
  }

  static async update(payload) {
    return await BaseService.callAPI(`users/${payload.id}`, "POST", payload)
  }
}

export default UserService
