import dayjs from "dayjs";
import BaseService from "./BaseService";

class ReportService {
  static async itemsStock(payload) {
    return await BaseService.callAPI("report/item/stocks", "GET", payload);
  }

  static async clientReport(payload) {
    return await BaseService.callAPI(
      `report/client/${payload.client}/${payload.type || "view"}?startDate=${
        payload.startDate || ""
      }&endDate=${payload.endDate || ""}`,
      "GET",
      payload,
      payload.type === "excel" ? { responseType: "blob" } : {}
    );
  }

  static async itemHistory(payload) {
    return await BaseService.callAPI(
      `report/item/history/${payload.item}?page=${
        payload.page || 1
      }&startDate=${payload.startDate || ""}&endDate=${
        payload.endDate || ""
      }&clientId=${payload.client || ""}`,
      "GET",
      payload
    );
  }

  static async itemReport(payload) {
    return await BaseService.callAPI(
      `report/item/${payload.type || "view"}?item=${
        payload.item || ""
      }&startDate=${payload.startDate || ""}&endDate=${payload.endDate || ""}`,
      "GET",
      payload,
      payload.type === "excel" ? { responseType: "blob" } : {}
    );
  }

  static async itemStockBalanceReport(payload) {
    return await BaseService.callAPI(
      `report/items/balance?type=${payload.type || "all"}&grade=${
        payload.grade || "all"
      }&date=${payload.date || dayjs().format("YYYY-MM-DD")}`,
      "GET",
      payload
    );
  }
}

export default ReportService;
