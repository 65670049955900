import { IconButton } from "@fluentui/react"
import dayjs from "dayjs"

export const getColumns = (onAction) => {
    return [
        {
            key: "clientName",
            name: "Client Name",
            fieldName: "clientName",
            data: 'string',
        },
        {
            key: "openingBalance",
            name: "Opening Balance (INR)",
            fieldName: "openingBalance",
            minWidth: 250,
        },
        {
            key: "openingBalanceDate",
            name: "Opening Balance Last Updated On",
            fieldName: "openingBalanceDate",
            minWidth: 250,
            onRender: (item, index, columns) => {
                return dayjs(item.openingBalanceDate, "YYYY-MM-DD").format("DD MMMM, YYYY")
            }
        },
        {
            key: "closingBalance",
            name: "Outstanding Balance (INR)",
            fieldName: "closingBalance",
            minWidth: 250,
            onRender: (item, index, columns) => {
                return item.closingBalance - item.openingBalance
            }
        },
        {
            key: "action",
            name: "Actions",
            fieldName: "actions",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item, index, column) => {
                return (
                    <IconButton
                        iconProps={{ iconName: 'Edit' }}
                        onClick={() => onAction("edit", item)}
                    />
                )
            }
        },
    ]
}