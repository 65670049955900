import React from "react"
import { PrimaryButton } from "@fluentui/react"
import "./pageHeader.scss"

class PageHeader extends React.Component {
    render() {
        const { title, actions, onAction, searchComponent } = this.props
        return (
            <div className="page-header no-print">
                <div className="page-title">
                    <span className="page-title-text">{title}</span>
                    {(searchComponent && (<div className="search-bar">
                        {searchComponent}
                    </div>)) || null}
                </div>
                <div className="actions">
                    {actions && actions.map(action => {
                        return (
                            <PrimaryButton
                                key={action} type="button" onClick={() => onAction(action)}>
                                <span className="captalise">
                                    {action.toLowerCase()}
                                </span>
                            </PrimaryButton>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default PageHeader