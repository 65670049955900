import React from "react"
import { connect } from "react-redux"
import { Redirect, withRouter } from "react-router-dom"
import { Route, Switch } from "react-router-dom"
import { Stack, Nav, MessageBar, MessageBarType } from "@fluentui/react"
import { me, updateUserPassword } from "../store/actions/userActions"
import { menus } from "../utils/menu"
import { Modal } from "../components/shared/modal"
import ResetPasswordForm from "../components/forms/resetPasswordForm"

const navStyles = {
    root: {
        width: 250,
        height: "100%",
        boxSizing: 'border-box',
        borderRight: '1px solid #eee',
        overflowY: 'auto',
    },
};

const subMenus = [
    'reports'
]

class PrivateNavigation extends React.Component {

    state = {
        appReady: false,
        updatePassword: false,
    }

    componentDidMount() {
        const { me } = this.props
        me()
    }

    componentDidUpdate(prevProps) {
        const { appReady, updatePassword } = this.state
        const { session, me } = this.props
        if (prevProps.session.requesting && !session.requesting && !appReady && !updatePassword) {
            this.setState({
                appReady: true
            })
        }

        if (prevProps.session.requesting && !session.requesting && updatePassword) {
            this.setState({
                updatePassword: false
            })
            me()
        }
    }

    renderRoute = (route) => {
        return (route.route && (<Route key={route.route} exact={true} path={route.route} component={route.component} />)) || null;
    }

    onMenuClick = (e, menu) => {
        const { history } = this.props;
        e.preventDefault()
        history.push(menu.route)
    }

    onResetPasswordSubmit = (values) => {
        const { updateUserPassword } = this.props
        this.setState({
            updatePassword: true
        })
        updateUserPassword(values)
    }

    render() {
        const { appReady } = this.state
        const { session, location } = this.props
        const userAccess = session.user.details.access
        let activeMenus = menus.filter(menu => menu.access.includes(userAccess))
        const defaultMenu = activeMenus[0] || null
        const locationParts = location.pathname.split("/").filter(item => item)
        let activeKey = locationParts.length ? locationParts[0] : "dashboard"
        if (subMenus.includes(activeKey)) {
            activeKey = locationParts.join("-")
            const mainMenu = activeMenus.find(menu => menu.links && menu.links.find(subMenu => subMenu.key === activeKey))
            mainMenu.isExpanded = true
        } else {
            activeMenus.forEach(item => {
                if (item.links) {
                    item.isExpanded = false
                }
            })
        }
        const error = (session && session.errorBody && session.errorBody.errors && session.errorBody.errors) || ""

        return (appReady && defaultMenu && (
            <>
                <Stack
                    horizontal
                    tokens={{ childrenGap: 15 }}
                    className="full-height"
                >
                    <Nav
                        className="no-print"
                        onLinkClick={this.onMenuClick}
                        selectedKey={activeKey}
                        styles={navStyles}
                        groups={[{
                            links: activeMenus
                        }]}
                    />
                    <div className="menu-page">
                        <Switch>
                            {activeMenus.map(menu => {
                                if (menu.subRoutes || menu.links) {
                                    let routes = []
                                    if (menu.route) {
                                        routes.push(this.renderRoute(menu))
                                    }
                                    routes.push(menu.subRoutes && menu.subRoutes.map(subMenu => {
                                        return this.renderRoute(subMenu)
                                    }))
                                    routes.push(menu.links && menu.links.map(subMenu => {
                                        return this.renderRoute(subMenu)
                                    }))
                                    return routes
                                } else {
                                    return this.renderRoute(menu)
                                }
                            })}
                            <Redirect to={defaultMenu.route} />
                        </Switch>
                    </div>
                </Stack>
                <Modal
                    hideModal={!session.user.details.isResetPassword}
                    title="Update Your Password"
                    subText="Please update your password to secure your account."
                    children={
                        <>
                            {error && error.error && <MessageBar className="errorBox ucwords" messageBarType={MessageBarType.error}>{error.error}</MessageBar>}
                            <ResetPasswordForm onSubmit={this.onResetPasswordSubmit} />
                        </>
                    }
                />
            </>
        )) || null
    }
}

const mapStateToProps = ({ session }) => ({
    session
})

const mapDispatchToProps = () => ({
    me,
    updateUserPassword
})

export default connect(mapStateToProps, mapDispatchToProps())(withRouter(PrivateNavigation));