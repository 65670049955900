import BaseService from "./BaseService"

class MetaService {

    static async clients(payload) {
        return await BaseService.callAPI(`meta/clients`, "GET", payload)
    }

    static async items(payload) {
        return await BaseService.callAPI(`meta/items`, "GET", payload)
    }
}

export default MetaService