import * as React from 'react'
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from "@fluentui/react"

class Modal extends React.Component {

    render() {
        const {
            cancelText,
            children,
            className,
            confirmText,
            hideModal,
            onCancel,
            onConfirm,
            subText,
            title,
            toggleHideModal,
            type,
        } = this.props

        const modalPropsStyles = {
            main: {
                maxWidth: 450
            }
        }

        const dialogContentProps = {
            type: type || DialogType.largeHeader,
            title: title,
            subText: subText,
        };

        return (
            <Dialog
                hidden={hideModal}
                onDismiss={toggleHideModal}
                dialogContentProps={dialogContentProps}
                modalProps={{
                    isBlocking: true,
                    className: `modal-container ${className || ''}`,
                    styles: modalPropsStyles
                }}
            >
                {children || null}
                {((confirmText || cancelText) && (
                    <DialogFooter>
                        {(confirmText && (<PrimaryButton onClick={onConfirm} text={confirmText || "Ok"} />)) || null}
                        {(cancelText && (<DefaultButton onClick={onCancel} text={cancelText || "Cancel"} />)) || null}
                    </DialogFooter>
                )) || null}
            </Dialog>
        )
    }

}

export default Modal