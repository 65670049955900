import * as actions from "../actions/actionTypes"
import { meta } from "./initialState"

const metaReducer = (state = meta, action) => {
    switch (action.type) {
        case actions.META_CLIENT_LIST_SUCCESS:
            return {
                ...state,
                clients: action.payload.list,
            }
        case actions.META_ITEM_LIST_SUCCESS:
            return {
                ...state,
                items: action.payload.list,
            }
        default:
            return state
    }
}

export default metaReducer