import { all, call, takeEvery, put, takeLatest } from "redux-saga/effects"
import StockService from "../../services/StockService"
import * as actions from "../actions/actionTypes"
import {
    getStockListSuccess,
    getStockListError,
    addStockSuccess,
    addStockError,
    getStockSuccess,
    getStockError,
    updateStockSuccess,
    updateStockError,
    downloadStocksExcelSuccess,
    downloadStocksExcelError,
    approveStockSuccess,
    approveStockError
} from "../actions/stockActions"
import { downloadFile } from "../../utils/common"


function* list(data) {
    try {
        const ajax = yield call(StockService.list, data.payload)
        if (ajax.status === 200) {
            yield put(
                getStockListSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                getStockListError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            getStockListError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

function* downloadExcel(data) {
    try {
        const ajax = yield call(StockService.downloadExcel, data.payload)
        if (ajax.status === 200) {
            downloadFile(ajax)
            yield put(
                downloadStocksExcelSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                downloadStocksExcelError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            downloadStocksExcelError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}


function* add(data) {
    try {
        const calls = data.payload.data.map(item => call(StockService.add, {
            ...item,
            accessToken: data.payload.accessToken
        }))
        const ajax = yield all(calls)
        let errors = 0;
        ajax.forEach(item => {
            if (item.status !== 201) {
                errors += 1
            }
        });
        if (errors < data.payload.data.length) {
            yield put(
                addStockSuccess({
                    status: 201
                })
            )
        } else if (errors) {
            yield put(
                addStockError({
                    status: 500
                })
            )
        }
    } catch (error) {
        yield put(
            addStockError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

function* get(data) {
    try {
        const ajax = yield call(StockService.get, data.payload)
        if (ajax.status === 200) {
            yield put(
                getStockSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                getStockError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            getStockError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

function* update(data) {
    try {
        const ajax = yield call(StockService.update, data.payload)
        if (ajax.status === 200) {
            yield put(
                updateStockSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                updateStockError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            updateStockError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

function* approve(data) {
    try {
        const ajax = yield call(StockService.approve, data.payload)
        if (ajax.status === 200) {
            yield put(
                approveStockSuccess({
                    status: ajax.status,
                    ...ajax.data.response,
                })
            )
        } else {
            yield put(
                approveStockError({
                    status: ajax.status,
                    errors: ajax.data.response,
                })
            )
        }
    } catch (error) {
        yield put(
            approveStockError({
                status: error.response.status || 500,
                errors: (error.response && error.response.data && error.response.data.response) || {},
                message: (error && error.message) || "Error. Please try again later",
            })
        )
    }
}

export default function* stockSaga() {
    yield all([
        takeLatest(actions.STOCK_LIST_REQUEST, list),
        takeEvery(actions.STOCK_ADD_REQUEST, add),
        takeEvery(actions.STOCK_GET_REQUEST, get),
        takeLatest(actions.STOCK_UPDATE_REQUEST, update),
        takeLatest(actions.STOCK_DOWNLOAD_EXCEL_REQUEST, downloadExcel),
        takeLatest(actions.STOCK_UPDATE_APPROVE_REQUEST, approve),
    ])
}