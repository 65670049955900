import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import { Stack, PrimaryButton } from "@fluentui/react";
import dayjs from "dayjs";
import * as FormHepler from "./formHelper";
import * as stockContants from "../../components/pages/stocks/constants";

class StockSearchForm extends React.Component {
  componentDidMount() {
    const { change } = this.props;
    change("startDate", dayjs().startOf("week").toDate());
  }

  onClear = () => {
    const { change } = this.props;
    change("client", null);
    change("entryType", null);
    change("user", null);
  };

  onDownload = () => {
    const { onDownload } = this.props;
    if (onDownload) {
      onDownload();
    }
  };

  onPrint = () => {
    const { onPrint } = this.props;
    if (onPrint) {
      onPrint();
    }
  };

  render() {
    const {
      handleSubmit,
      startDate,
      clients,
      canDownload,
      userAccess,
      forReport,
      onPrint,
      users,
      showOptionForUnApproved,
    } = this.props;
    return (
      <form onSubmit={handleSubmit} noValidate className="no-print">
        <Stack tokens={{ childrenGap: 15 }}>
          <Stack horizontal={true} tokens={{ childrenGap: 15 }}>
            <Field
              name="startDate"
              placeholder="Select Start Date"
              component={FormHepler.RenderDatePicker}
              maxDate={new Date()}
            />
            <Field
              name="endDate"
              placeholder="Select End Date"
              component={FormHepler.RenderDatePicker}
              maxDate={new Date()}
              minDate={startDate || new Date()}
            />
            <Field
              iconProps={{ iconName: "Search" }}
              name="client"
              placeholder="Search By Client"
              width={200}
              component={FormHepler.RenderSearchDropDown}
              options={clients.map((client) => ({
                value: client.id,
                label: client.clientName,
              }))}
            />
            {(!forReport && (
              <Field
                iconProps={{ iconName: "Search" }}
                name="entryType"
                placeholder="Search By Entry Type"
                options={stockContants.ENTRY_TYPES.filter((item) =>
                  item.access.includes(userAccess)
                )}
                component={FormHepler.RenderDropDown}
              />
            )) ||
              null}
          </Stack>
          <Stack horizontal={true} tokens={{ childrenGap: 15 }}>
            {(showOptionForUnApproved && (
              <Field
                label="Unapproved"
                name="onlyUnApproved"
                component={FormHepler.RenderToggleControl}
              />
            )) ||
              null}
            {(userAccess === "SA" && (
              <Field
                iconProps={{ iconName: "Search" }}
                name="user"
                placeholder="Search For User"
                width={200}
                component={FormHepler.RenderSearchDropDown}
                options={users.map((client) => ({
                  value: client.id,
                  label: client.name,
                }))}
              />
            )) ||
              null}
            <PrimaryButton type="button" onClick={this.onClear}>
              Clear Search
            </PrimaryButton>
            {(userAccess === "SA" && (
              <PrimaryButton
                disabled={!canDownload}
                iconProps={{ iconName: "ExcelDocument" }}
                type="button"
                onClick={this.onDownload}
              >
                Download
              </PrimaryButton>
            )) ||
              null}
            {(userAccess === "SA" && forReport && onPrint && (
              <PrimaryButton
                disabled={!canDownload}
                iconProps={{ iconName: "Print" }}
                type="button"
                onClick={this.onPrint}
              >
                Print
              </PrimaryButton>
            )) ||
              null}
          </Stack>
        </Stack>
      </form>
    );
  }
}

const StockSearchFormMapped = reduxForm({
  form: "stockSearchForm",
  enableReinitialize: true,
})(StockSearchForm);

const mapDispatchToProps = () => ({
  change,
});

const getInitialForm = (state) => {
  return {
    startDate: dayjs().startOf("week").toDate(),
    endDate: "",
  };
};

const selector = formValueSelector("stockSearchForm");
const mapStateToProps = (state) => ({
  initialValues: getInitialForm(state),
  startDate: selector(state, "startDate"),
  clients: state.meta.clients,
  users: state.users?.list?.data || [],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockSearchFormMapped);
