import React from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import * as stockContants from "../../../components/pages/stocks/constants";

class ItemReport extends React.Component {
  render() {
    const { itemReport = {}, item = "", startDate, endDate } = this.props;
    const data = itemReport?.list?.data || [];
    let closingNoOfPieces = itemReport?.list?.openingNoOfPieces || 0;
    let closingWeight = itemReport?.list?.openingWeight || 0;

    data?.forEach((item) => {
      if (item.entryType === stockContants.STOCK_OUT) {
        closingNoOfPieces -=
          (item.entryType.includes("STOCK") && item.numberOfPieces) || 0;
        closingWeight -= (item.entryType.includes("STOCK") && item.weight) || 0;
      } else if (item.entryType === stockContants.STOCK_IN) {
        closingNoOfPieces +=
          (item.entryType.includes("STOCK") && item.numberOfPieces) || 0;
        closingWeight += (item.entryType.includes("STOCK") && item.weight) || 0;
      }
    });

    return (
      <div className="client-report" id="client_report_print">
        {(data?.length && item && (
          <>
            <div className="report-hdr">
              <div className="data-row">
                <div className="label">Item:</div>
                <div className="value">{item || ""}</div>
              </div>
              <div className="data-row">
                <div className="label">Start Date:</div>
                <div className="value">
                  {dayjs(startDate, "YYYY-MM-DD").format("DD MMM, YYYY")}
                </div>
              </div>
              <div className="data-row">
                <div className="label">End Date:</div>
                <div className="value">
                  {dayjs(endDate, "YYYY-MM-DD").format("DD MMM, YYYY")}
                </div>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Client</th>
                  <th>Type</th>
                  <th>Nos</th>
                  <th>Weight</th>
                  <th>Rate</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colSpan={3}
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    Opening Balance
                  </td>
                  <td style={{ textAlign: "center", fontWeight: "bold" }}>
                    {itemReport?.list?.openingNoOfPieces || 0}
                  </td>
                  <td style={{ textAlign: "center", fontWeight: "bold" }}>
                    {itemReport?.list?.openingWeight || 0}
                  </td>
                  <td></td>
                </tr>
                {data?.map((item) => (
                  <tr key={item.id}>
                    <td style={{ textAlign: "center" }}>
                      {dayjs(item.stockDate, "YYYY-MM-DD").format(
                        "DD MMM, YYYY"
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.client.clientName || "-"}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {stockContants.ENTRY_TYPES.find(
                        (type) => type.key === item.entryType
                      )?.text || "-"}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {(item.entryType.includes("STOCK") &&
                        item.numberOfPieces) ||
                        "-"}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {(item.entryType.includes("STOCK") && item.weight) || "-"}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {(item.entryType.includes("STOCK") && item.rate) || "-"}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td
                    colSpan={3}
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    Closing Balance
                  </td>
                  <td style={{ textAlign: "center", fontWeight: "bold" }}>
                    {closingNoOfPieces}
                  </td>
                  <td style={{ textAlign: "center", fontWeight: "bold" }}>
                    {closingWeight}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </>
        )) ||
          null}
      </div>
    );
  }
}

const mapStateToProps = ({ reports }) => ({
  itemReport: reports.item,
});

export default connect(mapStateToProps)(ItemReport);
