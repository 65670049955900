const request = {
  requesting: false,
  error: false,
  errorBody: null,
  action: null,
};

const objectModule = {
  list: {},
  query: {},
  record: null,
  ...request,
};

export const session = {
  user: null,
  ...request,
};

export const loader = {
  requestedApis: 0,
};

export const global = {
  serviceErrorStatus: 0,
};

export const clients = {
  ...objectModule,
};

export const users = {
  ...objectModule,
};

export const stocks = {
  ...objectModule,
};

export const cashbooks = {
  ...objectModule,
};

export const items = {
  ...objectModule,
};

export const meta = {
  clients: [],
  items: [],
};

export const reports = {
  itemsStock: {
    requesting: false,
    list: [],
  },
  clientReport: {
    data: null,
    requesting: false,
  },
  itemHistory: {
    data: null,
    requesting: false,
  },
  item: {
    requesting: false,
    list: [],
  },
};

export const initialState = {
  session,
  loader,
  global,
  clients,
  stocks,
  users,
  cashbooks,
  meta,
  items,
  reports,
};
