import { IconButton } from "@fluentui/react"
import { ENTRY_TYPES, STOCK_IN, STOCK_OUT } from "../stocks/constants"
import dayjs from "dayjs"

export const itemHistoryColumns = (onAction) => {
    return [
        {
            key: "client.clientName",
            name: "Client Name",
            fieldName: "client.clientName",
            onRender: (item, index, columns) => {
                return item.client.clientName
            }
        },
        {
            key: "numberOfPieces",
            name: "No. of Pieces",
            fieldName: "numberOfPieces",
        },
        {
            key: "weight",
            name: "Weight",
            fieldName: "weight",
        },
        {
            key: "entryType",
            name: "Type",
            fieldName: "entryType",
            data: 'string',
            onRender: (item, index, columns) => {
                const entry = ENTRY_TYPES.find(entryType => entryType.key === item.entryType)
                return entry.text
            }
        },
        {
            key: "itemType",
            name: "Item",
            fieldName: "itemType",
            data: 'string',
            onRender: (item, index, columns) => {
                const entry = ENTRY_TYPES.find(entryType => entryType.key === item.entryType)
                return ((entry.key === STOCK_IN || entry.key === STOCK_OUT) && `${item.item.itemName}/${item.item.size}/${item.item.grade}`) || "-"
            },
            minWidth: 200,
        },
        {
            key: "stockDate",
            name: "Date Added",
            fieldName: "stockDate",
            onRender: (item, index, columns) => {
                return dayjs(item.stockDate, "YYYY-MM-DD").format("DD MMM, YYYY")
            }
        },
        {
            key: "action",
            name: "Actions",
            fieldName: "actions",
            minWidth: 200,
            maxWidth: 200,
            onRender: (item, index, column) => {
                return (
                    <IconButton
                        iconProps={{ iconName: 'Edit' }}
                        onClick={() => onAction("edit", item)}
                    />
                )
            }
        }
    ]
};