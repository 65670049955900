import * as actions from "../actions/actionTypes";
import { stocks } from "./initialState";

const stockReducer = (state = stocks, action) => {
  switch (action.type) {
    case actions.STOCK_LIST_REQUEST:
      return {
        ...state,
        requesting: true,
        error: false,
        errorBody: null,
        record: null,
      };
    case actions.STOCK_UPDATE_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actions.STOCK_ADD_REQUEST:
    case actions.STOCK_GET_REQUEST:
    case actions.STOCK_DOWNLOAD_EXCEL_REQUEST:
    case actions.STOCK_UPDATE_APPROVE_REQUEST:
      return {
        ...state,
        requesting: true,
        error: false,
        errorBody: null,
        record: null,
      };
    case actions.STOCK_UPDATE_REQUEST:
      return {
        ...state,
        requesting: true,
        error: false,
        errorBody: null,
      };
    case actions.STOCK_LIST_SUCCESS:
      return {
        ...state,
        requesting: false,
        list: action.payload,
      };
    case actions.STOCK_ADD_SUCCESS:
    case actions.STOCK_UPDATE_SUCCESS:
    case actions.STOCK_DOWNLOAD_EXCEL_SUCCESS:
    case actions.STOCK_UPDATE_APPROVE_SUCCESS:
      return {
        ...state,
        requesting: false,
      };
    case actions.STOCK_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        record: action.payload,
      };
    case actions.STOCK_ADD_ERROR:
    case actions.STOCK_GET_ERROR:
    case actions.STOCK_LIST_ERROR:
    case actions.STOCK_UPDATE_ERROR:
    case actions.STOCK_DOWNLOAD_EXCEL_ERROR:
    case actions.STOCK_UPDATE_APPROVE_ERROR:
      return {
        ...state,
        requesting: false,
        error: true,
        errorBody: action.payload,
      };
    default:
      return state;
  }
};

export default stockReducer;
