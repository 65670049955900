import React from "react"
import { connect } from "react-redux"
import { Text, MessageBar, MessageBarType } from "@fluentui/react"
import LoginForm from "../forms/loginForm"
import { login } from "../../store/actions/userActions"

class LoginPage extends React.Component {

    onSubmit = (values) => {
        const { login } = this.props;
        login({
            companyName: values.companyName,
            phoneNumber: values.phoneNo,
            password: values.password
        });
    }

    render() {

        const { session } = this.props
        const error = (session && session.errorBody && session.errorBody.errors && session.errorBody.errors) || ""
        return (
            <div className="box sm m-top-20">
                <Text variant="large">Login</Text>
                {error && error.error && <MessageBar className="errorBox ucwords" messageBarType={MessageBarType.error}>{error.error}</MessageBar>}
                <LoginForm onSubmit={this.onSubmit} />
            </div>
        )
    }
}

const mapStateToProps = ({ session }) => ({
    session
})

const mapDispatchToProps = () => ({
    login
});

export default connect(mapStateToProps, mapDispatchToProps())(LoginPage);