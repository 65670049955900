import { createStore, compose, applyMiddleware } from "redux"
import createReducer from "./reducers"
import createSagaMiddleware from "redux-saga"
import * as storage from "./persistStore"
import { initialState } from "./reducers/initialState"
import sagas from "./sagas"
import axiosInterceptor from "./middlewares/axiosMiddleware"

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

export const configureStore = () => {
  let store = null

  const sagaMiddleware = createSagaMiddleware()

  let storedSession = storage.getItem(storage.STORAGE_KEY)

  const appliedMiddleware = applyMiddleware(
    axiosInterceptor,
    sagaMiddleware
  )

  if (storedSession === null) {
    store = createStore(createReducer(), composeEnhancers(appliedMiddleware))
    const newState = JSON.stringify(store.getState())
    storage.setItem(storage.STORAGE_KEY, newState)
  } else {
    storedSession = JSON.parse(storedSession)

    const session = {
      ...initialState.session,
      ...(storedSession.session || {}),
      errorBody: null,
    }

    store = createStore(
      createReducer(),
      { ...initialState, ...storedSession, form: null, session, loader: initialState.loader },
      composeEnhancers(appliedMiddleware)
    )
  }

  sagaMiddleware.run(sagas)
  store.subscribe(() => {
    const newState = JSON.stringify(store.getState())
    storage.setItem(storage.STORAGE_KEY, newState)
  })

  return store
}
