import React from "react"
import { Field, reduxForm } from "redux-form"
import { Stack, PrimaryButton } from "@fluentui/react"
import * as FormHepler from "./formHelper"
import * as Validations from "./validation"

class ResetPasswordForm extends React.Component {

    constructor(props) {
        super(props)

        this.passwordMinLength = Validations.minLength(6)
    }

    render() {
        const { handleSubmit } = this.props
        return (
            <form onSubmit={handleSubmit} noValidate>
                <Stack
                    tokens={{ childrenGap: 15 }}
                    styles={{ root: { width: 300 } }}
                >
                    <Field
                        name="oldPassword"
                        label="Current Password"
                        type="password"
                        required={true}
                        canRevealPassword={true}
                        minLength="6"
                        component={FormHepler.RenderTextField}
                        validate={[Validations.required, this.passwordMinLength]}
                    />
                    <Field
                        name="newPassword"
                        label="New Password"
                        type="password"
                        required={true}
                        canRevealPassword={true}
                        minLength="6"
                        component={FormHepler.RenderTextField}
                        validate={[Validations.required, this.passwordMinLength, Validations.oldPasswordSame]}
                    />
                    <Field
                        name="confirmNewPassword"
                        label="Confirm New Password"
                        type="password"
                        required={true}
                        canRevealPassword={true}
                        component={FormHepler.RenderTextField}
                        validate={[Validations.required, Validations.checkConfirmPassword]}
                    />
                    <PrimaryButton type="submit">Update Password</PrimaryButton>
                </Stack>
            </form>
        )
    }
}

const ResetPasswordFormMapped = reduxForm({
    form: "resetPasswordForm",
    enableReinitialize: true,
})(ResetPasswordForm)

export default ResetPasswordFormMapped