import React from "react"

class Tile extends React.Component {
    render() {
        const { title, count, onClick } = this.props
        return (
            <div className="tile" onClick={onClick}>
                <div className="title">
                    {title}
                </div>
                <div className="count">
                    {count}
                </div>
            </div>
        )
    }
}

export default Tile