import React from "react"
import { connect } from "react-redux"
import dayjs from "dayjs"
import StockForm from "../../forms/stockForm"
import { PageHeader } from "../../shared/pageHeader"
import { getClientList, getItemList } from "../../../store/actions/metaActions"
import { getStock, addStock, updateStock } from "../../../store/actions/stockActions"
import StockMultipleForm from "../../forms/stockMultipleForm"

class StockAddEditPage extends React.Component {

    state = {
        edit: false,
        id: 0,
        formSubmitted: false
    }

    componentDidMount() {
        const { match, getClientList, getStock, getItemList } = this.props
        if (match.params && match.params.id) {
            this.setState({
                id: parseInt(match.params.id),
                edit: true
            }, () => {
                getStock({
                    id: match.params.id
                })
            })
        }
        getClientList()
        getItemList()
    }

    componentDidUpdate(prevProps) {
        const { stocks, history } = this.props
        const { formSubmitted } = this.state
        if (prevProps.stocks.requesting && !stocks.requesting && formSubmitted) {
            history.push("/stocks")
        }
    }

    onSubmit = (values) => {
        const { addStock, updateStock, stocks } = this.props
        const { edit } = this.state
        this.setState({
            formSubmitted: true
        })
        if (!edit) {
            const formPayload = values.stocks.map(item => ({
                ...item,
                clientId: item.clientId.value,
                entryType: item.entryType.key,
                itemId: (item.itemId && item.itemId.value) || "",
                stockDate: dayjs(item.stockDate).format("YYYY-MM-DD")
            }))
            addStock(formPayload)
        } else {
            const formPayload = {
                ...values,
                clientId: values.clientId.value,
                entryType: values.entryType.key,
                itemId: (values.itemId && values.itemId.value) || "",
                stockDate: dayjs(values.stockDate).format("YYYY-MM-DD")
            }
            updateStock({
                ...formPayload,
                id: stocks.record.id
            })
        }
    }

    render() {
        const { edit } = this.state
        return (
            <>
                <PageHeader title={`${edit ? "Edit" : "Add"} Stocks`} />
                {(edit && <StockForm onSubmit={this.onSubmit} edit={edit} />) || <StockMultipleForm onSubmit={this.onSubmit} />}
            </>
        )
    }
}


const mapStateToProps = ({ stocks }) => ({
    stocks
})

const mapDispatchToProps = () => ({
    getClientList,
    getStock,
    addStock,
    updateStock,
    getItemList
})

export default connect(mapStateToProps, mapDispatchToProps())(StockAddEditPage)