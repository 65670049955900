import * as actions from "./actionTypes"

export const getItemList = (payload) => ({
    type: actions.ITEM_LIST_REQUEST,
    payload
})

export const getItemListSuccess = (payload) => ({
    type: actions.ITEM_LIST_SUCCESS,
    payload
})

export const getItemListError = (payload) => ({
    type: actions.ITEM_LIST_ERROR,
    payload
})

export const addItem = (payload) => ({
    type: actions.ITEM_ADD_REQUEST,
    payload
})

export const addItemSuccess = (payload) => ({
    type: actions.ITEM_ADD_SUCCESS,
    payload
})

export const addItemError = (payload) => ({
    type: actions.ITEM_ADD_ERROR,
    payload
})

export const getItem = (payload) => ({
    type: actions.ITEM_GET_REQUEST,
    payload
})

export const getItemSuccess = (payload) => ({
    type: actions.ITEM_GET_SUCCESS,
    payload
})

export const getItemError = (payload) => ({
    type: actions.ITEM_GET_ERROR,
    payload
})

export const updateItem = (payload) => ({
    type: actions.ITEM_UPDATE_REQUEST,
    payload
})

export const updateItemSuccess = (payload) => ({
    type: actions.ITEM_UPDATE_SUCCESS,
    payload
})

export const updateItemError = (payload) => ({
    type: actions.ITEM_UPDATE_ERROR,
    payload
})