import { IconButton } from "@fluentui/react";
import dayjs from "dayjs";

export const STOCK_IN = "STOCK_IN";
export const STOCK_OUT = "STOCK_OUT";
export const CASH_IN = "CASH_IN";
export const CASH_OUT = "CASH_OUT";

export const ENTRY_TYPES = [
  {
    key: STOCK_IN,
    text: "Stock In",
    access: ["SA", "EMP"],
  },
  {
    key: STOCK_OUT,
    text: "Stock Out",
    access: ["SA", "EMP"],
  },
  {
    key: CASH_IN,
    text: "Cash In",
    access: ["SA"],
  },
  {
    key: CASH_OUT,
    text: "Cash Out",
    access: ["SA"],
  },
];

export const getColumns = (onAction, userAccess) => {
  let columns = [
    {
      key: "client.clientName",
      name: "Client Name",
      fieldName: "client.clientName",
      onRender: (item, index, columns) => {
        return item.client.clientName;
      },
    },
    {
      key: "entryType",
      name: "Type",
      fieldName: "entryType",
      data: "string",
      onRender: (item, index, columns) => {
        const entry = ENTRY_TYPES.find(
          (entryType) => entryType.key === item.entryType
        );
        return entry.text;
      },
    },
    {
      key: "itemType",
      name: "Item",
      fieldName: "itemType",
      data: "string",
      onRender: (item, index, columns) => {
        const entry = ENTRY_TYPES.find(
          (entryType) => entryType.key === item.entryType
        );
        return (
          ((entry.key === STOCK_IN || entry.key === STOCK_OUT) &&
            `${item.item.itemName}/${item.item.size}/${item.item.grade}`) ||
          "-"
        );
      },
      minWidth: 200,
    },
    {
      key: "stockWeight",
      name: "Weight",
      fieldName: "weight",
      data: "string",
      onRender: (item, index, columns) => {
        return item.weight || "-";
      },
    },
    {
      key: "stockNumberOfPieces",
      name: "No. of Pieces",
      fieldName: "numberOfPieces",
      data: "string",
      onRender: (item, index, columns) => {
        return item.numberOfPieces || "-";
      },
    },
    {
      key: "totalAmount",
      name: "Total Amount",
      fieldName: "totalAmount",
      data: "string",
      onRender: (item, index, columns) => {
        return item.totalAmount || "-";
      },
    },
    {
      key: "stockDate",
      name: "Date Added",
      fieldName: "stockDate",
      onRender: (item, index, columns) => {
        return dayjs(item.stockDate, "YYYY-MM-DD").format("DD MMM, YYYY");
      },
    },
    {
      key: "updatedBy.name",
      name: "Last Updated By",
      minWidth: 200,
      fieldName: "updatedBy.name",
      onRender: (item, index, columns) => {
        return item.updatedBy.name || "-";
      },
    },
    {
      key: "updatedAt",
      name: "Last Updated At",
      minWidth: 200,
      fieldName: "updatedAt",
      onRender: (item, index, columns) => {
        return dayjs(item.updatedAt).format("DD MMM, YYYY hh:mm:ss a");
      },
    },
    {
      key: "approvedBy.name",
      name: "Approved By",
      fieldName: "approvedBy.name",
      onRender: (item, index, columns) => {
        return (item.approvedBy && item.approvedBy.name) || "-";
      },
    },
  ];

  if (userAccess === "SA") {
    columns.push({
      key: "action",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item, index, column) => {
        return (
          <>
            {(!!!item.approvedBy && (
              <IconButton
                iconProps={{ iconName: "TriggerApproval" }}
                onClick={() => onAction("approve", item)}
              />
            )) ||
              null}
            <IconButton
              iconProps={{ iconName: "Edit" }}
              onClick={() => onAction("edit", item)}
            />
          </>
        );
      },
    });
  }

  return columns;
};
