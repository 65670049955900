import React from "react"
import { Spinner, SpinnerSize } from "@fluentui/react"
import { connect } from "react-redux"
import "./appLoader.scss"

class AppLoader extends React.Component {
    render() {
        const { loader: { requestedApis } } = this.props

        return (requestedApis && (
            <div className="app-loader container">
                <Spinner size={SpinnerSize.large} label="Please wait." />
            </div>
        )) || null
    }
}

const mapStateToProps = ({ loader }) => ({
    loader
})

export default connect(mapStateToProps)(AppLoader)