import { toast } from "react-toastify"
import { logout } from "../actions/userActions"
import _ from "lodash"

toast.configure()

const axiosInterceptor = (store) => (next) => (currentAction) => {
  const action = {
    ...currentAction,
  }
  if (action && action.type) {
    const state = store.getState()
    const session = state.session || {}
    if (action.type.includes("API_REQUEST")) {
      action.payload = action.payload || {}
      if (session.user && session.user.accessToken) {
        const actualToken = session.user.accessToken.split("|")
        if (Array.isArray(action.payload)) {
          action.payload = {
            data: action.payload
          }
        }
        action.payload.accessToken = actualToken[actualToken.length - 1];
      }
    } else {
      if (action.type.includes("API_ERROR")) {
        if (action.payload.status === 403) {
          toast.error("You are not authorized to perform this action.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          })

          store.dispatch(logout())
        } else if (action.payload.status === 401) {
          if (session && session.user && session.user.accessToken) {
            // log out
            store.dispatch(logout())
          }
        } else if (action.payload.status === 500) {
          toast.error(
            "Sorry, we are currently facing an issue to process your request. Please try again later.",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            }
          )
        }
      } else if (action.type.includes("API_SUCCESS")) {
        let module = action.type.replace("_API_SUCCESS", "")
        if (action.payload.status === 201) {
          module = module.replace("ADD", "").replaceAll("_", " ")
          module = _.trim(_.startCase(_.toLower(module)))
          toast.success(`${module} added successfully.`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          })
        } else if (action.payload.status === 200 && module.includes("UPDATE")) {
          module = module.replace("UPDATE", "").replaceAll("_", " ")
          module = _.trim(_.startCase(_.toLower(module)))

          if (action.payload.policy) {
            module = _.trim(
              _.startCase(_.toLower(action.payload.policy.replace("_", " ")))
            )
          }
          toast.success(`${module} updated successfully.`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          })
        } else if (action.payload.status === 200 && module.includes("DELETE")) {
          module = module.replace("DELETE", "").replaceAll("_", " ")
          module = _.trim(_.startCase(_.toLower(module)))
          toast.success(`${module} removed successfully.`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          })
        }
      }
    }
  }
  next(action)
}

export default axiosInterceptor
