import BaseService from "./BaseService";

class StockService {
  static async list(payload) {
    return await BaseService.callAPI(
      `stocks?page=${payload.page || 1}&startDate=${
        payload.startDate || ""
      }&endDate=${payload.endDate || ""}&clientId=${
        payload.client || ""
      }&entryType=${payload.entryType || ""}&onlyUnApproved=${
        payload.onlyUnApproved || false
      }&userId=${payload.user || ""}`,
      "GET",
      payload
    );
  }

  static async downloadExcel(payload) {
    return await BaseService.callAPI(
      `stocks/report/excel?page=${payload.page || 1}&startDate=${
        payload.startDate || ""
      }&endDate=${payload.endDate || ""}&clientId=${
        payload.client || ""
      }&entryType=${payload.entryType || ""}&onlyUnApproved=${
        payload.onlyUnApproved || false
      }&userId=${payload.user || ""}`,
      "GET",
      payload,
      {
        responseType: "blob",
      }
    );
  }

  static async add(payload) {
    return await BaseService.callAPI("stocks", "PUT", payload);
  }

  static async get(payload) {
    return await BaseService.callAPI(`stocks/${payload.id}`, "GET", payload);
  }

  static async update(payload) {
    return await BaseService.callAPI(`stocks/${payload.id}`, "POST", payload);
  }

  static async approve(payload) {
    return await BaseService.callAPI(
      `stocks/${payload.id}/approve`,
      "POST",
      payload
    );
  }
}

export default StockService;
