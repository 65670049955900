import React from "react"
import { connect } from "react-redux"
import { formValueSelector } from "redux-form"
import { List } from "../../shared/list"
import { PageHeader } from "../../shared/pageHeader"
import { getColumns } from "./constants"
import { getItemList } from "../../../store/actions/itemActions"
import TextSearchForm from "../../forms/textSearchForm"
import _ from "lodash"

class ItemListPage extends React.Component {

    state = {
        page: 1,
        searchQuery: ""
    }

    componentDidMount() {
        this.getItems()
    }

    componentDidUpdate(prevProps, prevState) {
        const { page, searchQuery } = this.state
        const { query } = this.props
        if (prevState.page !== page || prevState.searchQuery !== searchQuery) {
            this.getItems()
        }

        if (prevProps.query !== query) {
            this.setState({
                searchQuery: _.trim(query)
            })
        }
    }

    getItems = () => {
        const { page, searchQuery } = this.state
        const { getItemList } = this.props
        getItemList({
            page,
            query: searchQuery
        })
    }

    onPageHeaderActionClick = (action, addtionalParams) => {
        const { history } = this.props
        if (action === "add") {
            history.push("/items/add")
        } else if (action === "edit") {
            history.push(`/items/edit/${addtionalParams.id}`)
        } else if (action === "history") {
            history.push(`/items/history/${addtionalParams.id}`)
        }
    }

    onPageChange = (page) => {
        this.setState({
            page: page + 1
        }, () => {
            this.getItems()
        })
    }

    onTextFormSubmit = (values) => {
        this.setState({
            searchQuery: _.trim(values.query)
        })
    }

    render() {
        const { items } = this.props
        return (
            <>
                <PageHeader
                    title="Items"
                    actions={['add']}
                    onAction={this.onPageHeaderActionClick}
                    searchComponent={<TextSearchForm onSubmit={this.onTextFormSubmit} />}
                />
                <List
                    pagination={(items.list && items.list.pagination) || {}}
                    columns={getColumns(this.onPageHeaderActionClick)}
                    data={(items.list && items.list.data) || []}
                    isLoading={items.requesting}
                    onPageChange={this.onPageChange}
                    sortCol="itemName"
                />
            </>
        )
    }
}
const selector = formValueSelector("textSearchForm")
const mapStateToProps = ({ items, ...state }) => ({ items, query: selector(state, 'query') })

const mapDispatchToProps = () => ({
    getItemList
})

export default connect(mapStateToProps, mapDispatchToProps())(ItemListPage)