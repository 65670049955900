import BaseService from "./BaseService"

class ClientService {

    static async list(payload) {
        return await BaseService.callAPI(`clients?page=${payload.page || 1}&query=${payload.query || ""}`, "GET", payload)
    }

    static async add(payload) {
        return await BaseService.callAPI("clients", "PUT", payload)
    }

    static async get(payload) {
        return await BaseService.callAPI(`clients/${payload.id}`, "GET", payload)
    }

    static async update(payload) {
        return await BaseService.callAPI(`clients/${payload.id}`, "POST", payload)
    }
}

export default ClientService