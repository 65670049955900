import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Stack, PrimaryButton } from "@fluentui/react";
import dayjs from "dayjs";
import { getClientList } from "../../store/actions/clientActions";
import * as FormHepler from "./formHelper";
import * as Validations from "./validation";
import * as CashbookConstants from "../pages/cashbook/constants";

class CashbookForm extends React.Component {
  componentDidMount() {
    const { getClientList } = this.props;
    getClientList();
  }

  render() {
    const { handleSubmit, edit, clients, cashbooks } = this.props;
    return (
      <form onSubmit={handleSubmit} noValidate className="admin-forms">
        <Stack tokens={{ childrenGap: 15 }}>
          <Stack horizontal tokens={{ childrenGap: 15 }}>
            <Field
              name="cashType"
              label="Type"
              placeholder="Select Type"
              required={true}
              component={FormHepler.RenderDropDown}
              options={CashbookConstants.ENTRY_TYPES}
              validate={Validations.required}
              // disabled={(cashbooks.record && true) || false}
            />
            <Field
              name="client"
              label="Client"
              required={true}
              placeholder="Select Client"
              component={FormHepler.RenderDropDown}
              options={clients.map((client) => ({
                key: client.id,
                text: client.clientName,
              }))}
              validate={Validations.required}
              // disabled={(cashbooks.record && true) || false}
            />
            <Field
              name="cashDate"
              placeholder="Select Entry Date"
              component={FormHepler.RenderDatePicker}
              required={true}
              label="Entry Date"
              maxDate={new Date()}
            />
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 15 }}>
            <Field
              name="amount"
              label="Amount"
              type="number"
              required={true}
              component={FormHepler.RenderTextField}
              validate={Validations.required}
            />
          </Stack>
          <Stack
            horizontal
            tokens={{ childrenGap: 15 }}
            styles={{ root: { width: 500 } }}
          >
            <Field
              styles={{ root: { width: 500 } }}
              name="remark"
              label="Remark"
              multiline={true}
              resizable={false}
              component={FormHepler.RenderTextField}
            />
          </Stack>
          <div className="form-actions">
            <PrimaryButton type="submit">
              {edit ? "Update" : "Add"} Cashbook
            </PrimaryButton>
          </div>
        </Stack>
      </form>
    );
  }
}

const CashbookFormMapped = reduxForm({
  form: "cashbookForm",
  enableReinitialize: true,
})(CashbookForm);

const getInitialForm = (record, clients) => {
  if (record && clients) {
    const entryType =
      (record &&
        CashbookConstants.ENTRY_TYPES.find(
          (item) => item.key === record.cashType
        )) ||
      null;
    const client =
      (record &&
        clients &&
        clients
          .map((item) => ({
            key: item.id,
            text: item.clientName,
          }))
          .find((item) => item.key === record.client.id)) ||
      null;
    const cashDate =
      (record && dayjs(record.cashDate, "YYYY-MM-DD").toDate()) || new Date();
    return {
      ...record,
      client: client,
      cashType: entryType || CashbookConstants.ENTRY_TYPES[0],
      cashDate,
    };
  } else {
    return {
      amount: 0,
      cashType: CashbookConstants.ENTRY_TYPES[0],
    };
  }
};

const mapStateToProps = ({ cashbooks, meta, session, ...state }) => ({
  cashbooks,
  initialValues: getInitialForm(cashbooks.record || null, meta.clients || null),
  clients: meta.clients,
});

const mapDispatchToProps = () => ({
  getClientList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(CashbookFormMapped);
